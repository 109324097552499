import KewpieEvent from "./KewpieEvent.js";

class KewpieDataEvent extends KewpieEvent {
    #type;
    #keypath;
    #targetPath;
    #value;
    #oldValue;
    #hash;
    #oldHash;
    #kewpie;

    constructor({
        type,
        keypath,
        targetPath,
        value,
        oldValue = null,
        hash = null,
        oldHash = null,
        kewpie = null
    } = {}) {
        super();
        this.#type = type;
        this.#keypath = keypath;
        this.#targetPath = targetPath;
        this.#value = value;
        this.#oldValue = oldValue;
        this.#hash = hash;
        this.#oldHash = oldHash;
        this.#kewpie = kewpie;        
    }

    serialize(){
        return {
            keypath: this.keypath,
            value: this.value,
            type: this.type,
            oldValue: this.oldValue,
            hash: this.hash,
            oldHash: this.oldHash,
            target: this.targetPath,
        }
    }
    toJSON(){
        return this.serialize();
    }

    get keypath() {
        return this.#keypath;
    }

    get value() {
        return this.#value;
    }
    get kewpie() {
        return this.#kewpie;
    }
    get type() {
        return this.#type;
    }
    get oldValue() {
        return this.#oldValue;
    }
    get hash() {
        return this.#hash;
    }
    get oldHash() {
        return this.#oldHash;
    }
    get targetPath() {
        return this.#targetPath;
    }

    static get type(){
        return 'KewpieDataEvent'
    }

}



export default KewpieDataEvent;

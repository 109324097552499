
class EnumMap {
    #type;
    #map = {};

    constructor(type, map) {
        this.#type = type;
        this.#map = map;
        for (var key in map) {
            this[key] = map[key];
        }
    }

    tryFrom(value){
        for (var key in this.#map) {
            if (this.#map[key] === value) {
                return key;
            }
        }
        if (this.#map[value] !== undefined) {
            return this.#map[value];
        }

        return null;
    }

        

}

export default EnumMap;

import React from 'react';
import AbstractComponent, { IStandardProps } from "../Shared/AbstractComponent";
import ComposedContent from "../Shared/ComposedContent";
import experts from "./expert-list.json";

export type IEasHero = IStandardProps;

class Experts extends AbstractComponent<IEasHero> {

    constructor(props: IEasHero) {
        super(props);
    }

    get classbase() {
        return 'experts-block';
    }

    render() {
        return (
            <section className={this.state.className}>
                <div className={this.getClass('inner')}>
                    <div className={this.getClass('content')}>
                        <ComposedContent
                            className={this.getClass("header")}
                            text={{
                                header: 'Meet with a Microsoft expert',
                                body: {
                                    text: [
                                        'Stop by our booth at the event to schedule a one-on-one appointment with one of our experts. This is your chance to ask questions specific to your organization, gain valuable insights, and connect with industry leaders.',
                                        'Browse the list below to discover Microsoft experts attending the event.',
                                        'Meet our experts'
                                    ],
                                }
                            }}
                        />
                        <div className={`${this.getClass("experts")} ${this.getClass("alt-render")}`}>
                            { experts.map((expert, idx) => {

                                return <ComposedContent
                                    key={idx}
                                    className={this.getClass("expert")}
                                    {...expert}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Experts;

import React, { Component } from 'react';
import AbstractFormBase from './AbstractFormBase';
import EasElementHelper from './EasElementHelper';
class EasFormFieldText extends AbstractFormBase {

    constructor(props) {
        super(props);
        this.state.pattern = (this.props.atts && this.props.atts.pattern) ?? this.pattern ?? props.def.pattern ?? null
    }

    get classbase() {
        return 'form-control';
    }

    get type() {
        return this.props.def.subtype ?? 'text';
    }

    onInput(e){
        let val = e.target.value.trim();
        if (val == '') {
            val = undefined;
        }
        this.value = val;
    }

    render() {
        this.ref = React.createRef();
        return (
            <div className={this.state.className}>
                <label 
                    className={this.getClass('label')} 
                    htmlFor={this.uuid}
                >
                    {this.paramString('label')}                
                    {this.hasFlag("required") && "*"}
                </label>

                <input 
                    ref={this.ref}
                    type={this.type}
                    className={this.getClass('input')} 
                    id={this.uuid} 
                    onInput={this.onInput.bind(this)}
                    onChange={this.onInput.bind(this)}
                    pattern={this.state.pattern}
                    defaultValue={this.state.value}
                    {...this.inputAtts}
                ></input>

                {this.props.def.instructions ? (
                    <div 
                        className={this.getClass('instructions')}
                        dangerouslySetInnerHTML={{__html:this.paramString('instructions')}}
                    ></div>
                ) : null}

            </div>
        )
    }
}

EasElementHelper.addType('text', EasFormFieldText);
export default EasFormFieldText;



class KewpieResponse {
    #data;
    #path;

    constructor(data, path) {
        this.#data = data;
        this.#path = path;
    }
    data(normalizer = null) {
        if (normalizer) {
            return normalizer(this.#data);
        }
        return this.#data;
    }
    get path() {
        return this.#path;
    }
    get isNull() {
        return this.#data === null || this.#data === undefined;
    }
    get isArray() {
        return Array.isArray(this.#data);
    }
}

export default KewpieResponse;
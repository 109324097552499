import React from 'react';
import AbstractComponent, { IStandardProps } from "../../Shared/AbstractComponent";

export type ITable = IStandardProps & {
    session_map: object,
    stats: any
};

type IStateArrayObj = {
    key: string,
    val: string
}

class AdminStats extends AbstractComponent<ITable> {

    get classbase() {
        return 'admin-stats';
    }

    getStatTitle(key:string) : string {
        if(this.props.session_map) { 
            const map_obj = (this.props.session_map as any)[key];
            if(map_obj) {
                return map_obj.stat_title;
            }
        }
        return "";
    }

    render() {
        return (
            <div className={this.classbase}>
                { (this.props.stats ?? []).map( ( stat_obj : IStateArrayObj ) => {
                    return (
                        <div key={stat_obj.key} className={this.getClass("stat_box")}>
                            <div className={this.getClass("stat_box", "inner")}>
                                <h2 className={this.getClass("stat_title")}>{ this.getStatTitle(stat_obj.key) }</h2>
                                <p className={this.getClass("stat_value")}>{stat_obj.val}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        )
    }
}

export default AdminStats;

import React, { Component } from 'react';
import AbstractFormBase from '../eas-react-forms-kewpie/lib/Forms/AbstractFormBase';
import EasElementHelper from '../eas-react-forms-kewpie/lib/Forms/EasElementHelper';
import { v4 as uuidv4 } from 'uuid';
import countryData from "../Data/non-marketing.json"

class EasComplianceBox extends AbstractFormBase {

    constructor(props) {
        super(props);
        if (!this.state.value) {
            this.valueInitial = (this.props.def && this.props.def.defaultValue) || [];            
        }
        this.parent.events.listen('change', (data) => {
            if (data.country) {
                this.setState({country: data.country});
            }
        });
    }

    get classbase() {
        return 'form-compliancebox';
    }

    // async onInput(evt) {
    //     let value = evt.target.value;
    //     this.value = value;
    // }

    countryByKey(key){
        let country = countryData.countries.find((country) => {
            return country.key === key;
        });
        return country;
    }
    getCurrentCountryMessage() {
        let key = this.state.country;
        let standardText = "By submitting this form, I agree to the collection and use of my personal information for administration of this program.";
        if (!key) {
            return (<div className={this.getClass('message')}>
                {standardText}
            </div>);
        }
        let country = this.countryByKey(key);
        if (country.special && country.special.override) {

            let identifier = `chk-${uuidv4()}`;

            return (<div className={this.getClass('forced')}>
                <input onChange={this.onItemChange.bind(this)} id={identifier} className="compliance" type="checkbox" required></input>
                <label for={identifier} dangerouslySetInnerHTML={{__html:country.special.override}}></label>
            </div>);
        } else if (country.special && country.special.above) {
            return (<div className={this.getClass('message','above')}>
                {country.special.above}
            </div>);
        } else {
            return (<div className={this.getClass('message')}>
                {standardText}
            </div>);
        }
    }

    onItemChange(evt) {
        if(this.ref && this.ref.current){
            this.value = new Date();
        }
    }
    
    render() {
        setTimeout(()=> {
            this.parent.checkValidity();
        }, 25)
        if (!this.ref) {
            this.ref = React.createRef();
        }

        return (
            <div className={this.state.className} ref={this.ref}>
                {this.getCurrentCountryMessage()}
                <div className={this.getClass('terms')}>
                    <a href="https://go.microsoft.com/fwlink/?LinkId=521839">Privacy statement</a>
                </div>
            </div>
        )
    }
}
EasElementHelper.addType('compliance', EasComplianceBox);

export default EasComplianceBox;

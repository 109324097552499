import KewpieException from "../Errors/KewpieException.js";
import KewpieConditional from "./KewpieConditional.js";

class KewpieConditionalCollection {
    #items = {};
    constructor() {
    }

    add(kewpieData, keypath) {
        let data = kewpieData.get(keypath);
        let key = kewpieData.get(`${keypath}.key`);

        if ((key == null) || (key == undefined)) {
            throw new KewpieException("Each conditional must have a key");
        }
        if (this.__items[key]) {
            throw new KewpieException("Key already exists");
        }
        let conditional = KewpieConditional.fromJson(data, kewpieData);
        this.#items.push(conditional);
    }

    byId(key) {
        return this.#items[key];
    }

}

export default KewpieConditionalCollection;
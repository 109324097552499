import React from 'react';
import AbstractComponent, { IStandardProps } from "../../Shared/AbstractComponent";

export type ITable = IStandardProps & {
    action: string,
    hideModal: any,
    confirmAction: any,
    user: any,
    submitting: boolean
}

class ConfirmationModal extends AbstractComponent<ITable> {

    constructor(props: ITable) {
        super(props);
    }

    get classbase() {
        return 'confirmation-modal';
    }

    get full_name() {
        return `${this.props.user.form_submission_meta.first_name} ${this.props.user.form_submission_meta.last_name}`;
    }

    render() {
        return (
            <div className={this.state.className}>
                <div className={this.getClass("inner")}>
                    {this.props.submitting && 
                        <h2>Submitting...</h2>
                    }
                    {!this.props.submitting &&
                        <div className={this.getClass("content")}>
                            <h2 className={this.getClass("title")}>Are you sure you wish to continue?</h2>
                            <p className={this.getClass("description")}>You are going to <span className={this.getClass("action-text", this.props.action)}>{this.props.action}</span> {this.full_name}.</p>
                            <div className={this.getClass("actions")}>
                                <button className={this.getClass("action-cancel")} onClick={this.props.hideModal}>Cancel</button>
                                <button className={this.getClass("action-submit")}  onClick={this.props.confirmAction}>Submit</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default ConfirmationModal;

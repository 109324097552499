import {Attributes} from "react";
export interface IAnyNonNullishValue {}

// let examplar = {
//     "modifiers" : {
//         "size" : ["small", "medium", "large"],
//     }
// }

export interface IClassModifier {
    [key: string]: string[] 
}

export interface IClassnameProps extends Attributes  {
    className?: string,
    identifier? : string,
    classModifiers?: IClassModifier,
    classModifiersExtra?: IClassModifier,
}

class ClassnameManager {
    private __baseClassName: string;
    private __mods: any =  {};
    private __identifier?: string;
    private __events:any = {};



    constructor(baseClassname: string, modifiers:any = {}, identifier?: string) {
        this.__baseClassName = baseClassname;
        this.__mods = modifiers;
        this.__identifier = identifier;
    }

    on(eventType:string, closure:Function) {
        if (!this.__events[eventType]) {
            this.__events[eventType] = [];
        }
        this.__events[eventType].push(closure);
    }
    fire(eventType:string, ...args:any[]) {
        if (this.__events[eventType]) {
            this.__events[eventType].forEach((closure:Function) => {
                closure(...args);
            });
        }
    }
    detectChange(props:IClassnameProps){
        if (props.className) {
            if (props.className !== this.__baseClassName) {
                this.resetBase(props.className);
            }    
        }

        if ((props.identifier) && (props.identifier !== this.__identifier)) {
            this.setIdentifier(props.identifier);
        }
    }

    static fromProps(props: IClassnameProps, baseName?:string):ClassnameManager {
        let ret = new ClassnameManager(props.className || baseName || '', {}, props.identifier || undefined);
        if (props.classModifiers) { ret.addModifierSet(props.classModifiers, true); }
        if (props.classModifiersExtra) { ret.addModifierSet(props.classModifiersExtra, true); }


        return ret;
    }

    getRootClass(styles?: string) {
        let ret:string[] = [this.__baseClassName];

        for (var ii=0; ii < Object.keys(this.__mods).length; ii++) {
            let key = Object.keys(this.__mods)[ii];
            if (this.__mods[key].length > 0) {
                ret = ret.concat(this.__mods[key].map((modifier: string) => `${this.__baseClassName}--${key}--${modifier}`));
            }
        }
        if (this.__identifier) {
            ret.push(`${this.__baseClassName}--id--${this.__identifier}`);
        }
        if (styles) {
            ret.push(styles);
        }

        return ret.join(' ');
    }

    getClass(...suffix: string[]) {
        return [this.__baseClassName, ...suffix].join('__');
    }

    joinClass(styles: IAnyNonNullishValue, ...suffix: string[]) {
        return `${styles} ${this.getClass(...suffix)}`;
    }

    resetBase(base:string){
        this.__baseClassName = base;
        this.fire('change');
    }
    setIdentifier(id:string) {
        this.__identifier = id;
        this.fire('change');
    }

    addModifier(key: string, value: string, supressEvent:boolean = false) {
        if (!this.__mods[key]) {
            this.__mods[key] = [];
        }
        this.__mods[key].push(value);
        if (!supressEvent) {
            this.fire('change');
        }
    }
    addModifierSet(modifiers:IClassModifier, supressEvent:boolean = false ):void {
        for (var key in modifiers) {
            for (var ii=0; ii < modifiers[key].length; ii++) {
                this.addModifier(key, modifiers[key][ii], true);
            }
        }
        if (!supressEvent) {
            this.fire('change');
        }
    }

    setModifier(key: string, value: string, supressEvent:boolean = false):void {
        this.__mods[key] = [value];
        if (!supressEvent) {
            this.fire('change');
        }
    }

    unsetModifier(key: string, value: string, supressEvent:boolean = false):void {
        if (this.__mods[key]) {
            this.__mods[key] = this.__mods[key].filter((mod: string) => mod !== value);
        }
        if (!supressEvent) {
            this.fire('change');
        }
    }
    hasModifierGroup(key: string):boolean {
        return (this.__mods[key] && this.__mods[key].length > 0);
    }
    hasModifier(key: string, item:string):boolean {
        return (this.__mods[key] && this.__mods[key].includes(item));
    }

    getModifiedClass(
        modifiers: any,
        ...suffix: string[]
        ):string {
        
        let base = this.getClass(...suffix);
        let ret = [base];

        for (var key in modifiers) {
            for (var ii=0; ii < modifiers[key].length; ii++) {
                ret.push(`${base}--${key}--${modifiers[key][ii]}`);
            }
        }
        return ret.join(' ');
    }


}

export default ClassnameManager;
import React from 'react';
import AbstractComponent, { IStandardProps } from "../../Shared/AbstractComponent";
import ConfirmationModal from "./ConfirmationModal";

export interface ITableDataRow {
    form_submission_id: number,
    form_submission_meta: any,
    disabled: boolean,
    test?: string
}

export interface ITableProps extends ITableProxyProps { };

export interface ITableProxyProps extends IStandardProps {
    table_data: ITableDataRow[],
    activity: string,
    update_stats: Function,
    setTableData: Function
};

class Table extends AbstractComponent<ITableProps> {

    get classbase() {
        return 'admin-waitlist-table';
    }

    async approveUser(user_id : number){
        await fetch(`/api/approveUser?`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body:  JSON.stringify({
                user_id,
                activity: this.props.activity
            })

        })
        this.removeUser(user_id)
    }

    async denyUser(user_id : number){
        await fetch(`/api/denyUser?`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body:  JSON.stringify({
                user_id,
                activity: this.props.activity
            })

        })
        this.removeUser(user_id)
    }

    removeUser(form_submission_id : number) {
        const new_users = this.props.table_data.filter( ( user : ITableDataRow ) => {
            return user.form_submission_id != form_submission_id;
        })
        this.props.setTableData(new_users);
    }


    showModal(user: ITableDataRow, action: string) {
        this.setState({
            currentUser: user,
            confirmationAction: action
        });
    }

    hideModal(){ 
        this.setState({
            confirmationAction: null,
            modalSubmitting: false
        });
    }

    async confirmAction() {
        this.setSetUserButtonState(this.state.currentUser.form_submission_id, true);
        this.hideModal();
        if(this.state.confirmationAction === "approve") {
            await this.approveUser(this.state.currentUser.form_submission_id);
        } else if(this.state.confirmationAction === "deny") {
            await this.denyUser(this.state.currentUser.form_submission_id);
        }
        this.props.update_stats();
    }

    setSetUserButtonState(form_submission_id: number, disable: boolean) {
        const updated_rows = this.props.table_data.map( ( user : ITableDataRow ) => {
            if(user.form_submission_id === form_submission_id) {
                user.disabled = disable;
            }
            return user;
        })
        this.props.setTableData(updated_rows);
    }

    render() {
        return (
            <div className={this.state.className}>
                <div className={this.getClass("inner")}>
                    <table >
                        <thead className={this.getClass("head")}>
                            <tr className={this.getClass("head__row")}>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Title</th>
                                <th>Email</th>
                                <th>Approve</th>
                                <th>Deny</th>
                            </tr>
                        </thead>
                        <tbody className={this.getClass("body")}>
                        {this.props.table_data && this.props.table_data.map((row:ITableDataRow) => {
                            return (
                                <tr key={row.form_submission_id} className={this.getClass("body__row")}>
                                    <td className={this.getClass("body__item")}>{row.form_submission_meta.first_name}</td>
                                    <td className={this.getClass("body__item")}>{row.form_submission_meta.last_name}</td>
                                    <td className={this.getClass("body__item")}>{row.form_submission_meta.title}</td>
                                    <td className={this.getClass("body__item")}>{row.form_submission_meta.email}</td>
                                    <td className={this.getClass("body__item", "action-approve")}>
                                        <button disabled={row.disabled}
                                            onClick={() => this.showModal(row, "approve")}>
                                            Approve
                                        </button>
                                    </td>
                                    <td className={this.getClass("body__item", "action-deny")}>
                                        <button disabled={row.disabled}
                                            onClick={() => this.showModal(row, "deny")}>
                                            Deny
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                { this.state.confirmationAction && 
                    <ConfirmationModal submitting={this.state.modalSubmitting} user={this.state.currentUser} action={this.state.confirmationAction} hideModal={() => this.hideModal()} confirmAction={() => this.confirmAction()}/>
                }
            </div>
        )
    }
}

const Proxy = (props: ITableProxyProps) => {

    const [tableData, setTableData] = React.useState(props.table_data);

    React.useEffect( () => {
        const incoming_table_data = props.table_data.map( row => {
            row.disabled = false;
            return row;
        });
        setTableData(incoming_table_data);
    }, [props.table_data])

    const updated_props = {...props, table_data: tableData, setTableData}

    return <Table {...updated_props}  />
}

export default Proxy;

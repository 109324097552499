
/*
    Does not extend CustomEvent ... because this is explictly
    not a DomEvent.
*/
class KewpieEvent {
    #isPropagationStopped = false;

    constructor({
    } = {}) {
        
    }

    static get type(){
        return 'KewpieEvent'
    }

    get isPropagationStopped() {
        return this.#isPropagationStopped;
    }

    stopPropagation() {
        this.#isPropagationStopped = true;
    }
}

KewpieEvent.TYPES ={
    SET: 'KewpieEvent::SET',
    PATCH: 'KewpieEvent::PATCH',
    CHANGE: 'KewpieEvent::CHANGE',
    UNSET: 'KewpieEvent::UNSET',
    OBSERVER: 'KewpieEvent::OBSERVER',
    CONDITIONAL : 'KewpieEvent::CONDITIONAL',
}


export default KewpieEvent;


class EasFormWizardManager {
    #parent;
    #options;
    #page = 0;

    constructor(options, parent){ 
        this.#options = options;
        this.#parent = parent;
        this.__hook();
    }

    get pageCount(){
        return this.#options.fields.length;
    }
    get pageNames(){
        return this.#options.fields.map((field) => field.name);
    }
    get isWizard(){
        return this.#options.wizard;
    }
    get page(){
        return this.#page;
    }   
    set page(page){
        this.#page = page;
        if (this.#parent && this.#parent.setModifier) {
            this.#parent.setModifier('wizard-page', page);
            this.update(page);
        }
    }
    get canNext(){
        return this.#page < this.pageCount - 1;
    }
    get canPrev(){
        return this.#page > 0;
    }

    state(page = null){
        if (page !== null) {
            this.#page = page;
        }
        return {
            wizardPage: this.#page,
            wizardCanNext : this.canNext,
            wizardCanPrev : this.canPrev,
            // className : (this.#parent && this.#parent.getRootClass) ? this.#parent.getRootClass() : null
        }
    }

    update(page=null) {
        if (this.#parent && this.#parent.setStateProxy) {
            this.#parent.setStateProxy(this.state(page));
        }
    }

    __hook(){
        if (this.#parent && this.#parent.events) {
            this.#parent.events.listen('onWizardNext', (evt) => this.next());
            this.#parent.events.listen('onWizardPrevious', (evt) => this.previous());
        }
    }

    previous(){
        this.page = this.#page - 1;
    }
    next(){
        this.page = this.#page + 1;
    }


}

export default EasFormWizardManager;
class EasEvents {

    static get LIFETIME_STANDARD () { return 'standard'; }
    static get LIFETIME_ONCE () { return 'once'; }

    constructor() {
        this.events = {};
    }

    listen(key, closure, is_once) {
        if (Array.isArray(key)) {
            for (var ii=0; ii < key.length; ii++) {
                this.listen(key[ii], closure, is_once);
            }
        }
        else {
            var lifetime = is_once ? EasEvents.LIFETIME_ONCE : EasEvents.LIFETIME_STANDARD;
    
            if (this.events[key] === undefined) {
                this.events[key] = {
                    listeners: []
                }
            } 
    
            this.events[key].listeners.push({
                'key' : key,
                'closure' : closure,
                'scope' : lifetime
            });
        }
    }

    once (key, closure) {
        this.listen(key, closure, true);
    }
    
    async fire (key, data) {
        if (!this.events[key]) {
            return;
        }
    
        var removals = [];
        for (var ii=0, len=this.events[key].listeners.length; ii < len; ii++) {
            if (!this.events[key].listeners[ii]) {
                continue;
            }
            if (this.events[key].listeners[ii].key == key) {
                if (this.events[key].listeners[ii].scope == EasEvents.LIFETIME_ONCE) {
                    removals.push(ii);
                }
                await this.events[key].listeners[ii].closure(data);
            }
        }
        for (var ii = removals.length-1; ii >= 0; --ii) {
            this.events[key].listeners.splice(removals[ii],1);
        }
    }
}

export default EasEvents;
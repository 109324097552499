import KewpieEvent from "./KewpieEvent.js";
import KewpiePath from "../KewpieCore/KewpiePath.js";

class KewpieEventListener {
    #dataType;
    #callback;
    #order = 0;
    #identifier;
    #keypath;
    #serial;
    #ready = true;

    constructor({dataType, callback, identifier = null, keypath = null, order = 100} = {}){
        this.#dataType = dataType;
        this.#callback = callback;
        this.#order = order * 100;
        this.#identifier = identifier;
        this.#keypath = keypath;
        this.#serial = KewpieEventListener.serial++;
    }

    serialize(){
        return {
            type: this.type,
            identifier: this.identifier,
            keypath: this.#keypath,
            order: this.order,
            ready: this.ready
        }
    }
    toJSON(){
        return this.serialize();
    }
    isListenerForIdentifier(identifier) {
        if (!this.ready) return false;
        if (!this.identifier) return false;
        return this.identifier == identifier;
    }
    isListenerForEvent(event) {
        if (!this.ready) return false;
        if (this.type != event.type) return false;
        if (this.dataType == KewpieEvent.TYPES.OBSERVER) {
            let kp = KewpiePath.coalesce(this.#keypath);
            return kp.match(event.keypath);
        }
        return true;
    }

    handle(event) {
        this.#callback(event);
    }

    cancel() {
        this.#ready = false;
    }

    get type(){
        return this.#dataType;
    }

    get keypath(){
        return this.#keypath;
    }

    get ready(){
        return this.#ready;
    }
    get order() {
        return this.#order + this.#serial;
    }
    get identifier() {
        return this.#identifier;
    }

}
KewpieEventListener.serial = 0;

export default KewpieEventListener;
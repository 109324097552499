import React, { Component } from 'react';
import AbstractFormBase from './AbstractFormBase';
import EasElementHelper from './EasElementHelper';

class EasFormFieldButtonGroup extends AbstractFormBase {
    #currentTarget;

    constructor(props) {
        super(props);
        if (!this.state.value) {
            this.valueInitial = (this.props.def && this.props.def.defaultValue) || [];            
        }
        this.setDefaults();
    }

    get classbase() {
        return 'form-buttongroup';
    }
    get items(){
        return this.props.def.items || [];
    }
    get extraItems(){
        return this.props.def.extraItems || [];
    }

    setDefaults(immediate=false){
        let index = 0;
        if (this.props.def.defaultValue) {
            for (var ii=0; ii < this.items.length; ii++){
                let item = this.items[ii];
                if (item.value == this.props.def.defaultValue) {
                    index = ii;
                    break;
                }
            }
        }
        if (immediate) {
            this.setModifier('current',0, true);
            this.setState({
                active: 0
            })    
        } else {
            this.state.active= 0;
            this.setModifier('current',0, false);
        }

        // let found = null;
        // for (var ii=0; ii < this.items.length; ii++){
        //     let item = this.items[ii];
        //     if (item.active) {
        //         found = ii;
        //     }
        // }
        // if (found == null) {
        //     found = this.props.def.active ?? null;
        // }
        // if ((found !== null) && (found != this.state.active)) {
        //     if (immediate) {
        //         this.setModifier('current',found);
        //         this.setState({
        //             active: found
        //         })    
        //     } else {
        //         this.setModifier('current',found,false);
        //         this.state.active = found;
        //     }
        // } else if (this.state.active !== 0) {
        //     if (immediate) {
        //         this.setModifier('current',0, true);
        //         this.setState({
        //             active: 0
        //         })    
        //     } else {
        //         this.state.active= 0;
        //         this.setModifier('current',0, false);
        //     }
        // }
    }

    clickHandler(evt){
        let ref = evt.target;
        let idx = parseInt(ref.dataset.idx);
        let val = this.items[idx].value;
        this.setModifier('current',idx);
        this.value = val;
    } 

    render() {
        this.ref = React.createRef();
        return (
            <div ref={this.ref} className={this.state.className}>

                {this.props.def.headline ? (
                    <div className={this.getClass('headline')}>{this.props.def.headline}</div>
                ):null}    
                {this.props.def.preamble ? (
                    <div className={this.getClass('preamble')}>{this.props.def.preamble}</div>
                ):null}    

                <div className={this.getClass('items')}>
                    {this.items.map(function(item, idx){
                        return (
                            <button 
                                className={this.getClass('button')} 
                                key={idx} 
                                type="button"
                                title={item.title}
                                onClick={this.clickHandler.bind(this)} 
                                data-idx={idx}>
                                    {item.text}
                            </button>        
                        )                
                    }.bind(this))}
                    {this.extraItems.length ? (
                        <div className={this.getClass('extra')}>
                            {this.extraItems.map(function(item, idx){
                                <button 
                                    className={this.getClass('extra','button')} 
                                    key={idx} 
                                    type="button"
                                    title={item.title}
                                    onClick={this.clickHandler.bind(this)} 
                                    data-idx={idx}>
                                        {item.text}
                                </button>        
                        }.bind(this))}
                        </div>
                    ) : null}
                </div>
                {this.props.def.instructions ? (
                    <div 
                        className={this.getClass('instructions')}
                        dangerouslySetInnerHTML={{__html:this.paramString('instructions')}}
                    ></div>
                ) : null}
            </div>
        )
    }
}
EasElementHelper.addType('buttongroup', EasFormFieldButtonGroup);

export default EasFormFieldButtonGroup;
import React from 'react';
import AbstractComponent, { IStandardProps } from "../Shared/AbstractComponent";
import ComposedContent from "../Shared/ComposedContent";

export type IEasHero = IStandardProps;

class Footer extends AbstractComponent<IEasHero> {

    constructor(props: IEasHero) {
        super(props);
    }

    get classbase() {
        return 'footer';
    }

    render() {
        return (
            <footer className={this.state.className}>
                <div className={this.getClass("inner")}>
                    <div className={this.getClass("content")}>
                        <div className={this.getClass("column", "one")}>
                            <ComposedContent
                                className={this.getClass("logo-content")}
                                media={{
                                    type: 'image',
                                    src: '/assets/ms-logo-black.svg',
                                    alt: 'logo'
                                }}
                            />
                        </div>
                        <div className={this.getClass("column", "two")}>
                            <ComposedContent
                                className={this.getClass("link")}
                                identifier="links"
                                text={{
                                    actions: [
                                        {
                                            as: 'link',
                                            href: 'https://go.microsoft.com/fwlink/?LinkId=521839',
                                            target: "_blank",
                                            text: 'Privacy policy'
                                        },
                                        {
                                            as: 'link',
                                            href: 'https://www.microsoft.com/en-us/legal/intellectualproperty/Trademarks/',
                                            target: "_blank",
                                            text: 'Trademarks'
                                        }
                                    ]
                                }}
                            />
                            <ComposedContent
                                className={this.getClass("link")}
                                identifier="hosted"
                                text={{
                                    body: {
                                        text: [
                                            'This site is hosted for Microsoft by Extreme Arts + Sciences.',
                                            'For support contact <a href="mailto:support@easci.com">support@easci.com</a>.',
                                            '©2024 Microsoft Corporation. All rights reserved.',
                                        ],
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

            </footer>
        )
    }
}

export default Footer;



function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

function isObjectOrArray(item) {
    return (item && typeof item === 'object' || Array.isArray(item));
}

function DeepMerge(target, source, concat_arrays = true) {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
        if (isObject(source[key])) {
            if (!(key in target)) {
                Object.assign(output, { [key]: source[key] });
            } else {
                output[key] = DeepMerge(target[key], source[key]);
            }
        } else if (concat_arrays && Array.isArray(source[key])) { 
            if (!(key in target)) { 
                output[key] = source[key]; 
            } else { 
                output[key] = output[key].concat(source[key]);
            }
        } else {
            Object.assign(output, { [key]: source[key] });
        }
        });
    }
    return output;
}

function Clone(obj, concat_arrays = true) {
    if (isObject(obj)) {
        return DeepMerge({}, obj, concat_arrays);
    }
    return obj;
}

function ArrayToObject(arr) {
    let ref = {};
    for (var ii=0; ii < arr.length; ii++) {
        ref[ii] = arr[ii];
    }
    return ref;
}


function WalkObject(obj, fn, keyArr = [], accum = {}){
    if (Array.isArray(obj)) {
        for (var ii=0; ii < obj.length; ii++) {
            WalkObject(obj[ii], fn, keyArr.slice().concat([ii]), accum);
        }
    } else if (typeof obj === 'object') {
        for (var key in obj) {
            WalkObject(obj[key], fn, keyArr.slice().concat([key]), accum);
        }
    } else {
        let joined = keyArr.join('.');
        let val = fn(obj, joined, keyArr);
        accum[joined] = val;
        return val;
    }
    return accum;
}

function CleanObject(obj) {
    if ((obj === null) || (obj === undefined)) {
        // nothing.
    } else if (Array.isArray(obj)) {
        return obj.map(function(item){
            return CleanObject(item);
        });
        // console.log(obj);
        // let ref = [];
        // for (var ii=0; ii < obj.length; ii++) {
        //     ref[ii] = CleanObject(obj[ii]);
        // }
        // obj = ref;
    } else if (typeof obj === 'object') {
        let keys = Object.keys(obj);

        let isNumbered = true;
        let accum = [];
        keys.forEach(function(key) {

            isNumbered = isNumbered && key.match(/^\d+$/);
            if (isNumbered) {
                accum[parseInt(key)] = obj[key];
            }
        });
        if (isNumbered) {
            for (var ii=0; ii < accum.length; ii++) {
                accum[ii] = CleanObject(accum[ii]);
            }
            obj = accum;
        } else {
            for (var key in obj) {
                obj[key] = CleanObject(obj[key]);
            }    
        }
    } else {
        return obj;
    }
    return obj;
}

function arrayDiff (arr1) { // eslint-disable-line camelcase
    //  discuss at: https://locutus.io/php/array_diff/
    // original by: Kevin van Zonneveld (https://kvz.io)
    // improved by: Sanjoy Roy
    //  revised by: Brett Zamir (https://brett-zamir.me)
    //   example 1: array_diff(['Kevin', 'van', 'Zonneveld'], ['van', 'Zonneveld'])
    //   returns 1: ['Kevin']
    const retArr = {}
    const argl = arguments.length
    let k1 = ''
    let i = 1
    let k = ''
    let arr = {}
    arr1keys: for (k1 in arr1) { // eslint-disable-line no-labels
      for (i = 1; i < argl; i++) {
        arr = arguments[i]
        for (k in arr) {
          if (arr[k] === arr1[k1]) {
            // If it reaches here, it was found in at least one array, so try next value
            continue arr1keys // eslint-disable-line no-labels
          }
        }
        retArr[k1] = arr1[k1]
      }
    }
    return Object.values(retArr);
}
function isScalar (mixedVar) { 
    return (/boolean|number|string/).test(typeof mixedVar)
}
function isNumeric (mixedVar) { 
    return (/number/).test(typeof mixedVar)
}

export {
    DeepMerge,
    isObject,
    isObjectOrArray,
    arrayDiff,
    isScalar,
    isNumeric,
    Clone,
    ArrayToObject,
    WalkObject,
    CleanObject
};
import KewpiePath from "../KewpieCore/KewpiePath.js";
import KewpieCondition from "./KewpieCondition.js";
import KewpieConditionEvent from "./KewpieConditionEvent.js";
import KewpieConditionDefinitionInvalidException from "./KewpieConditionDefinitionInvalidException.js";

class KewpieConditional {
    #conditions = [];
    #qp;
    #path;
    #valueIfTrue;
    #valueIfFalse;
    #meta;
    #current = undefined;


    constructor({qp, path, valueIfTrue, valueIfFalse, meta} = {}) {
        this.#qp = qp;
        this.#path = KewpiePath.coalesce(path, true);
        this.#valueIfTrue = valueIfTrue;
        this.#valueIfFalse = valueIfFalse;
        this.#meta = meta;
    }

    get qp(){
        return this.#qp;
    }
    get value(){
        return this.current ? this.#valueIfTrue : this.#valueIfFalse;
    }
    get current(){
        if (this.#current === undefined) {
            this.check();
        }
        return this.#current;
    }
    get meta(){
        return this.#meta;
    }
    get path(){
        return this.#path;
    }

    check(skipDispatch = false) {
        let sok = true;
        for (var ii=0;ii < this.#conditions.length; ii++) {
            sok = sok && this.#conditions[ii].current;
        }

        if (this.#current !== sok) {
            this.#current = sok;

            if (this.path && !this.path.isNull) {
                if (this.value === null) {
                    this.qp.unset(this.path);
                } else {
                    this.qp.set(this.path, this.value);
                }
            }
            if (!skipDispatch) {
                this.qp.events.dispatch(new KewpieConditionEvent(
                    this,
                    this.qp)
                );
            }
        }
        return this.#current;;
    }


    add({path, operator, value = null}={}){
        let ret = new KewpieCondition(path, operator, value, this);
        this.#conditions.push(ret);
        return ret;
    }


    static fromJson(arr, qp) {
        let ret = new this({
            qp,
            path: arr.path,
            valueIfTrue: arr.valueIfTrue,
            valueIfFalse: arr.valueIfFalse,
            meta: arr.meta
        });
        if (!arr.conditions) {
            throw new KewpieConditionDefinitionInvalidException("Invalid conditional array");
        }
        for (var condition in arr.conditions) {
            let conditionDef = arr.conditions[condition];
            ret.add(conditionDef);
        }
        ret.check(true);
        return ret;
    }

}

export default KewpieConditional;
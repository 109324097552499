import React, { Component } from 'react';
import AbstractFormBase from './AbstractFormBase';
import EasElementHelper from './EasElementHelper';
class EasFormFieldDisplay extends AbstractFormBase {

    constructor(props) {
        super(props);
    }

    get classbase() {
        return 'form-control';
    }

    get type() {
        return 'hidden';
    }



    onInput(e){
    }

    render() {

        this.ref = React.createRef();
        return (
            <div className={this.state.className}>
                <label 
                    className={this.getClass('label')} 
                >
                    {this.paramString('label')}                
                </label>

                <input 
                    ref={this.ref}
                    type="hidden"
                    defaultValue={this.value}
                    className={this.getClass('input')} 
                ></input>
                <div>
                    {this.value}
                </div>

                {this.props.def.instructions ? (
                    <div 
                        className={this.getClass('instructions')}
                        dangerouslySetInnerHTML={{__html:this.paramString('instructions')}}
                    ></div>
                ) : null}

            </div>
        )
    }
}

EasElementHelper.addType('display', EasFormFieldDisplay);
export default EasFormFieldDisplay;
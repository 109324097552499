import React, { Component } from 'react';
import EasElementHelper from "./EasElementHelper";
import AbstractFormBase from "./AbstractFormBase";
import { DeepMerge, Clone, EasEvents } from "../../../eas-node-util";
import EasFormFieldButton from "./EasFormFieldButton";
import Kewpie, {KewpieEventDispatcher, KewpieCustomEvent} from "../../../kewpie";
import EasFormWizardManager from './Helpers/EasFormWizardManager';

class EasForm extends AbstractFormBase {
    #isMounted = false;
    #qpdata;
    #elements;
    #ref = null;

    constructor(props){

        super(props);
        let base = this.getInitialvalues(this.def.fields);

        // this.events = new KewpieEventDispatcher();
        // this.events.listen('input', this.onInput.bind(this))

        this.events = new EasEvents();
        this.events.listen('input', this.onInput.bind(this));
        this.#qpdata = new Kewpie(base);
        this.#qpdata.patch(this.props.values ?? {});

        this.#qpdata.observe(null, (evt) => {
            this.setStateProxy({
                data: this.#qpdata.data()
            })
        });

        let localState = new Kewpie(this.state);
        let wizard = new EasFormWizardManager(this.def, this);
        if (wizard.isWizard) {
            this.wizard = wizard
            localState.patch(wizard.state());
        }
        localState.patch({
            fields: this.getConditionalDefs(this.def.fields),
            canSubmit: true,
            valid: false
        });
        this.state = localState.data();

        this.checkValidity();

    }

    get classbase() {
        return 'eas-form';
    }
    
    get type(){
        return null;
    }

    get elements(){
        return this.#elements;
    }
    get isMounted(){
        return this.#isMounted;
    }
    setStateProxy(state){
        if (this.#isMounted) {
            this.setState(state);
        } else {
            this.state = Object.assign(this.state, state);
        }
    }

    componentDidMount(){
        this.#isMounted = true;

        if (this.props.onLoad) {
            let evt = new CustomEvent('load', {
                detail: this.serialize()
            });
            this.props.onLoad(evt);
        }
        this.handleConditionals();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.values) !== JSON.stringify(this.props.values)) {
            let base = this.getInitialvalues(this.def.fields);
            this.#qpdata.patch(this.props.values ?? {});
            // this.#data = DeepMerge(base, this.props.values ?? {}, false);
            // this.setState({data: this.#data})
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        let s_ok = true;
        s_ok = s_ok && (nextProps.def.key == this.def.key);
        if (!s_ok) {
            this.handleConditionals();
            // this.onInput();
        }
        return true;
    }

    checkValidity(){
        let currentValidity = this.state.valid;
        let valid = true;

        if (this.#ref && this.#ref.current) {
            this.#ref.current.querySelectorAll('input,select,textarea').forEach((el) => {
                valid = valid && el.checkValidity();
            });
        } else {
            valid = false;
        }

        if (this.def.validation && this.def.validation.required) {
            for (var ii=0; ii < this.def.validation.required.length; ii++) {
                let val = this.#qpdata.get(this.def.validation.required[ii]);
                if ((val === undefined) || (val === null)) {
                    valid = false;
                }
            }

        }
        if (currentValidity != valid) {
            this.setStateProxy({valid});

            if (this.props.onValidityChange) {
                let evt = new CustomEvent('validitychange', {
                    detail: {
                        valid
                    }
                });
                this.props.onValidityChange(evt);
            }
        }
    }

    serialize(){
        let ret = this.#qpdata.data();
        ret = this.validateConditions(this.props.def.fields, ret);
        return ret;
    }

    getValue(key) {
        return this.#qpdata.get(key);
    }

    getInitialvalues(fields){
        let ret = {};

        for (var ii=0; ii < fields.length; ii++) {
            let field = fields[ii];
            if (!this.checkConditions(field)) {
                continue;
            }

            if (field.type == 'fieldset') {

                let subFields = this.getInitialvalues(field.fields);
                ret = Object.assign({}, ret, subFields);
            } else {

                if (field.value) {
                    ret[field.datakey] = field.value;
                } else if (field.defaultValue) {
                    ret[field.datakey] = field.defaultValue;
                } else if (field.type == 'togglebutton') {
                    ret[field.datakey] = false;
                }
            }
        }

        return ret;
    }

    checkConditions(field) {
        if (field.conditions) {

            for (var jj=0; jj < field.conditions.length; jj++) {
                let cond = field.conditions[jj];
                if (!this.#qpdata) {
                    return false;
                }
                let val = this.getValue(cond.ref);
                switch (cond.operator) {
                    case 'or':
                        if (!Array.isArray(cond.value)) {
                            throw new Error('OR operator requires an array of values');
                        }
                        if (!cond.value.includes(val)) {
                            return false;
                        }
                        break;
                    case 'equal':
                        if (val != cond.value) {
                            return false;
                        }
                        break;
                    case 'in':
                        if (!Array.isArray(val)) {
                            return false;
                        }
                        if (!val.includes(cond.value)) {
                            return false;
                        }
                        break;
                    default:
                        throw new Error(`Unknown operator ${cond.operator}`);
                }
            }
        }
        return true;
    }

    getConditionalDefs(fieldGroup){

        let allFields = Clone(fieldGroup);
        let ret = [];
        for (var ii=0;ii<allFields.length;ii++) {
            let field = Clone(allFields[ii]);
            if (field.type == 'fieldset') {
                let subFields = this.getConditionalDefs(field.fields);
                if (subFields.length) {
                    field = Object.assign(field, {
                        fields: subFields
                    })
                } else {
                    continue;
                }
            }
            if (this.checkConditions(field)) {
                ret.push(field);
            }
        }

        return ret;
    }

    validateConditions(fieldGroup, datamap){
        let allFields = fieldGroup;
        let ret = [];
        for (var ii=0;ii<allFields.length;ii++) {
            let field = allFields[ii];

            if (field.type == 'fieldset') {
                datamap = this.validateConditions(field.fields, datamap);
            }
            if (!this.checkConditions(field)) {
                delete datamap[field.datakey];

                // This doesn't recurse, so it will break if you try to nest fieldsets.
                if (field.type == 'fieldset') {
                    for (var jj=0; jj < field.fields.length; jj++) {
                        delete datamap[field.fields[jj].datakey];
                    }
                }
            }
        }
        return datamap;
    }

    handleConditionals(){

        let rendered = this.getConditionalDefs(this.def.fields);
        this.checkValidity();
        this.setStateProxy({
            fields: rendered
        });
    }

    onInput(e){
        // Should add immediate validation here so taht we can set update the 'canSubmit' state.
        if (e && e.detail && e.detail.identifier) {

            this.#qpdata.set('holder', '');
            this.#qpdata.set(e.detail.identifier, e.detail.value);

            this.handleConditionals();

            this.events.fire('change', this.serialize());

            if (this.props.onInput) {
                let evt = new CustomEvent('input', {
                    detail: this.serialize()
                });
                this.props.onInput(evt);
            }
        } else {
            // console.log(e);
        }
    }

    logFormData(){
        console.log(this.serialize());
    }

    formAction(evt) {
        // let action = evt.currentTarget.dataset.action;
        let action = evt.detail.action;
        if (!action) return;
        let outEvt = new CustomEvent(action,{
            detail: {
                value: this.serialize(),
                ref: this.ref
            }
        });
        if (this.props.onAction) {
            this.props.onAction(outEvt);
        }

        let onHandler = `on${action.charAt(0).toUpperCase() + action.slice(1)}`;
        if (this.props[onHandler]) {
            this.props[onHandler](outEvt);
        } else {
            // let kpEvt = new KewpieCustomEvent(onHandler, evt.detail);
            // this.events.dispatch(kpEvt);
        }
    }


    render() {

        this.checkValidity(false);
        if (!this.#ref) {
            this.#ref = React.createRef();
        }
        return ( 
            <form onSubmit={this.props.onSubmit} ref={this.#ref} className={this.state.className}>
                {this.props.def.title ? (
                    <h2 className={this.getClass('title')}>{this.props.def.title}</h2>
                ):null}
                {this.props.def.preamble ? (
                    <div className={this.getClass('preamble')}>{this.props.def.preamble}</div>
                ):null}
                <div className="eas-form__required">Please note that all fields marked with an asterisk (*) are required.</div>
                <div className={this.getClass('elements')}>
                    {
                        this.state.fields.map((field, idx) => {
                            let fieldRef = DeepMerge(field, {value: this.#qpdata.get(field.datakey)}, false);

                            if (fieldRef.type == 'fieldset') {
                                for (var ii=0; ii < fieldRef.fields.length; ii++) {
                                    fieldRef.fields[ii].value = this.#qpdata.get(fieldRef.fields[ii].datakey);
                                } 
                            } 

                            let controlIdx = `${(this.props.def && this.props.def.mode) ?? 'form'}-${fieldRef.datakey ?? fieldRef.key ?? idx}`;

                            return EasElementHelper.buildElement({
                                field:fieldRef, 
                                idx:controlIdx, 
                                parent:this,
                                value: this.#qpdata.get(fieldRef.datakey),
                                onInput: this.onInput.bind(this)
                            });
                        })
                    }
                </div>
                {
                    this.props.def.actions ?
                        this.props.def.actions.map((action, idx) => {
                            return (
                                <div className={this.getClass('actions')}>
                                    <EasFormFieldButton
                                        key={action.key ?? idx}
                                        action={action.action}
                                        disabled={!(this.state[action.enabled] ?? true)}
                                        onClick={this.formAction.bind(this)}
                                        text={action.label}
                                    ></EasFormFieldButton>
                                </div>

                            )

                        })
                    : null
                }

                {/* <div className={this.getClass('debug')} style={{display:"none"}}>
                    <button onClick={this.logFormData.bind(this)}>
                        Log Form Data
                    </button>
                </div> */}
            </form>
        )
    }

}

export default EasForm;

import { Clone, ArrayToObject,arrayDiff, WalkObject, isObjectOrArray,isObject, CleanObject } from "../util/ObjectUtils.js";
import EnumMap from "../util/EnumMap.js";

import KewpieHash from "./KewpieHash.js";
import KewpieEvent from "../Events/KewpieEvent.js";
import KewpieDataEvent from "../Events/KewpieDataEvent.js";
import KewpiePath from './KewpiePath.js';
import KewpieEventDispatcher from '../Events/KewpieEventDispatcher.js';
import KewpieResponse from "./KewpieResponse.js";

import KewpiePathNotFoundException from "../Errors/KewpiePathNotFoundException.js";
import KewpieConditionalCollection from "../Conditionals/KewpieConditionalCollection.js";
import KewpieInvalidOperationException from "../Errors/KewpieInvalidOperationException.js";



class KewpieData {
    #cache = {}; 
    #observeCache = null;
    #dispatcher;
    #kewpieConditionals;
    // #eventListeners = [];

    constructor(data = null){
        if (!data) {
            data = {};
        }
        if (Array.isArray(data)) {
            data = ArrayToObject(data);
        }
        if (!(data instanceof Object)) {
            data = {value: data};
        }
        this.__qpWorkCache = [];
        this.__qp_data = [];
        this.__qp_original = null;
        this.__data = data;
        this.__original = Clone(data);

        this.#kewpieConditionals = new KewpieConditionalCollection();

        this.#dispatcher = new KewpieEventDispatcher(this);
    }

    diff(kewpie){
        let orig = this.toKeypaths();
        let test = kewpie.toKeypaths();

        let retval = {
            changed: {},
            added: {},
            removed: {}
        };
        for (var key in test) {
            if (orig[key] ==  undefined) {
                retval.added[key] = test[key];
            } else if (orig[key] !== test[key]) {
                retval.changed[key] = test[key];
            }
        }
        for (var key in orig) {
            if (test[key] == undefined) {
                retval.removed[key] = orig[key];
            }
        }
        return retval;
    }

    static from_array(arr) {
        let ret = new this();
        ret.__originate(arr);
        return ret;
    }

    static fromKeypathArray(arr) {
        let ret = new this();
        for (var key in arr) {
            ret.set(key, arr[key], false);
        }

        ret.__clean()
        ret.__originate(ret.data());
        return ret;
    }

    static __qpObjToKpArr(obj, initialPathPrefix = '') {

        if (!obj || typeof obj !== 'object') {
            return [{ [initialPathPrefix]: obj }]
        }
        
        const prefix = initialPathPrefix
            ?  `${initialPathPrefix}.`
            : ''

        let keys = Array.isArray(obj) ? [...obj.keys()] : Object.keys(obj);

        if (Array.isArray(obj) && initialPathPrefix == '') {
            return obj;
        }

        if (!keys.length) {
            return {};
        }
        let keys2 = keys.map((key) => {
            return KewpiePath.encodePathPart(key)
        });



        return keys
            .flatMap((key,idx) =>
                this.__qpObjToKpArr(
                    obj[key],
                    `${prefix}${keys2[idx]}`,
                ),
            )
            .reduce((acc, path) => ({ ...acc, ...path }))
    }

    static __qpObjToKpArray(obj, initialPathPrefix = '') {
        let items = this.__qpObjToKpArr(obj, initialPathPrefix);

        let ret = [];
        for (var key in items) {
            ret.push({key, value: items[key]});
        }

        return ret;
    }



    toString(){
        return this.toJSON();
    }

    toJSON(){
        return JSON.stringify(this.data());
    }

    __hydrate(){}

    get normalizer(){
        return null;
    }

    /**
     * GETTERS
     */
    get(keypath){
        this.__hydrate();
        if (!keypath) {
            return Clone(this.__data);
        }
        let obj = this.__qpGetRaw(keypath);
        return obj.data(this.normalizer);
        // return this.__qpGetRaw(keypath).data(this.normalizer);
    }

    mget(...keypath){
        this.__hydrate();
        if (Array.isArray(keypath[0])) {
            keypath = keypath[0];
        }
        let ret = this.__qpGetMulti(keypath);
        return ret.data();
    }

    first(...keypaths) {
        this.__hydrate();
        for (var ii=0; ii < keypaths.length; ii++) {
            let val = this.get(keypaths[ii]);
            if (val !== undefined) {
                return val;
            }
        }
        return null;
    }

    has(keypath) {
        this.__hydrate();
        let val = this.get(keypath);
        return (val !== undefined);
    }

    require(keypath) {
        this.__hydrate();
        if (!keypath) {
            return Clone(this.__data);
        }
        let ret = this.__qpGetRaw(keypath);
        if (ret.isNull) {
            throw new KewpiePathNotFoundException(keypath);
        }
        return ret.data(this.normalizer);
    }
    
    search(target, keypath = null){
        let items = this.toKeypaths(keypath);
        let accum = {};
        for (var key in items) {
            if (typeof target == "Number") {
                if (items[key] === target) {
                    accum[key] = items[key];
                }    
            } else if (typeof target == 'String') {
                if (items[key].toLowerCase().indexOf(target.toLowerCase()) !== -1) {
                    accum[key] = items[key];
                }
            }
        }
        return Clone(accum);
    }

    isEmpty(){
        this.__hydrate();
        if (this.__qp_data == null) return true;
        if (Object.keys(this.__qp_data).length == 0) return true;
        return false;
    }

    /** 
     * SETTERS
     */

    set(keypath, value){
        this.__hydrate();

        if (Array.isArray(value) || value instanceof KewpieData) {
            this.unset(keypath);
            // this.__qpSetReal(keypath, value);
            this.__qpPatchReal(value, keypath, false, false);
        } else {
            this.__qpSetReal(keypath, value);
        }
    }

    unset(keypath) {
        this.__decache(keypath);
        let path = KewpiePath.coalesce(keypath);
        let pKpArr = path.to_kparr();
        var obj = this.__data;
        while (pKpArr.length -1) {
            var tPath = pKpArr.shift();
            if (!obj[tPath]) {
                obj[tPath] = {};
            }
            obj = obj[tPath];
        }
        delete obj[pKpArr[0]];
        this.__handleChange(keypath);
    }

    munset(...keypath) {
        if (Array.isArray(keypath[0])) {
            keypath = keypath[0];
        }

        for (var ii=0; ii < keypath.length; ii++) {
            this.unset(keypath[ii])
        }
    }

    patch(data, keypath = null) {
        this.__qpPatchReal(data, keypath, false, true);
        // dispatch.
        return this;
    }


    /**
     * Traversers
     */
    forEach(fn, key = null) {
        let sub = (key) ? this.subset(key) : Clone(this.__data);
        WalkObject(sub, fn);
    }

    // transform(fn, key = null) {
    //     let sub = (key) ? this.__get_direct(key) : this.__data;
    //     WalkObject(sub, fn);
    // }

    mapDeep(fn, key = null) {
        let sub = this.subset(key).data();
        let kpArr = WalkObject(sub, fn);
        let ret = KewpieData.fromKeypathArray(kpArr);
        return ret.data();
    }

    map(fn, key = null){
        let sub = (key) ? this.subset(key) : Clone(this.__data);
        let accum = [];
        if (Array.isArray(sub)) {
            for (var ii=0; ii < sub.length; ii++) {
                accum.push(fn(sub[ii], ii));
            }
        } else if (typeof sub === 'object') {
            for (var key in sub) {
                accum.push(fn(sub[key], key));
            }
        } else {
            accum.push(fn(sub, null));
        }

        return accum;
    }


    /**
     * AGGREGATORS
     */
    data(keypath = null){
        if (keypath) {
            return this.get(keypath);
        }
        return Clone(this.__data);
    }
    clone(){ 
        let items = this.toKeypaths();
        let ret = new KewpieData();
        for (var key in items) {
            ret.set(key, items[key], false);
        }

        return ret;
    }
    subset(keypath = null){ 
        let ref = this.get(keypath);
        return new KewpieData(ref);
    }

    prepend(orig) {

    }
    count(){
        this.__hydrate();
        throw "Not yet implemented.";
        return 0;
    }

    original(){
        return this.__original;
    }









    get events(){
        return this.#dispatcher;
    }


    //// STATIC CONSTRUCTORS
    // static from_array(arr) {
    //     return new this(arr);
    // }

    static from_keypath_array(arr) {
        let ret = new this();
        for (var key in arr) {
            ret.set(key, arr[key], false);
        }
        ret.__clean()
        ret.__original = Clone(ret.__data);
        return ret;
    }

   
    static __qpGetFromObject(keypath, obj, directPredicate = null) {
        let kp = KewpiePath.coalesce(keypath);

        if (directPredicate == null) {
            directPredicate = kp.getDirectPredicate();
        }
        if (directPredicate) {
            let data = this.__qpObjToKpArr(obj);
            for (var key in data) {
                if (!KewpiePath.testPredicate(directPredicate, data[key])) {
                    delete data[key];
                }
            }
            obj = this.fromKeypathArray(data).data();
        }

        if (!isObjectOrArray(obj)) {
            return new KewpieResponse(null, kp);
        }
        let pKpArr = kp.to_kparr();
        let ret = {};
        let kpAccum = [];
        for (var ii=0; ii < pKpArr.length; ii++) {
            let ndx = pKpArr[ii];
            kpAccum.push(ndx);

            if (ndx == '?') {
                if (isObjectOrArray(obj)) {
                    let pObj = obj;
                    if (isObject(obj)) {
                        pObj = new Map(Object.entries(obj));
                    }
                    for (const [key, tempvalue] of pObj) {
                        let diff = arrayDiff(pKpArr, kpAccum);

                        if (isObjectOrArray(tempvalue)) {
                            let kpa = diff.join('.');
                            let ref = this.__qpGetFromObject(kpa, tempvalue);
                            if (!ref.isNull) {
                                let accum = kpAccum.slice(0,-1);
                                ret[ [...accum, key, kpa].join('.') ] = ref;
                            }                            
                        } else if (diff.length === 0) {
                            let accum = kpAccum.slice(0,-1);
                            let kpa = [...accum,key].join('.')
                            ret[kpa] = new KewpieResponse(tempvalue, kpa);
                        }
                    }
                }
            } else {
                if (Array.isArray(obj)) {
                    obj = obj[parseInt(ndx)];
                } else if (isObject(obj)) {
                    obj = obj[ndx]
                }
            }
        }
        let out = obj;
        if (kp.is(KewpiePath.PATH.PATH_WILDCARD)) {
            out = new KewpieData();
            for (var key in ret) {
                out.set(key, ret[key].data());
            }
            out = out.data();
        }

        if (kp.is(KewpiePath.PATH.PATH_HAS_DEPTH)) {
            if (isObjectOrArray(out)) {
                let outref = KewpieData.from_array(out);
                out = outref.limit(kp.depth).data();
            }
        }

        return new KewpieResponse(out, kp);


        // while (pKpArr.length) {
        //     var part = pKpArr.shift();
        //     if (obj[part]) {
        //         obj = obj[part];
        //     } else {
        //         return new KewpieResponse(undefined, keypath);
        //     }
        // }
        // return new KewpieResponse(obj, keypath);
    }

    //// PROTECTED / INTERNALS GENERAL
    __qpGetDirect(keypath) {
        return this.constructor.__qpGetFromObject(keypath, this.__data);
    }

    __qpGetRaw(keypath){
        if (!this.#cache[keypath]) {
            let obj = this.__qpGetDirect(keypath);
            this.__encache(keypath, obj);
       }
       return this.#cache[keypath];
    }
    __qpGetMulti(keypaths){
        if (!Array.isArray(keypaths)) {
            keypaths = [keypaths];
        }
        let ret = new KewpieData({});
        for (var ii=0; ii < keypaths.length; ii++) {
            let val = this.__qpGetRaw(keypaths[ii]);
            if (!val.isNull) {
                ret.set(keypaths[ii], val, false);
            }
        }
        ret.__clean();
        return ret;
    }

    __qpSetReal(keypath, value, skipChangeDetect = false){
        let path = KewpiePath.coalesce(keypath);

        if (value instanceof KewpieResponse) {
            value = value.data();
        }

        let pKpArr = path.to_kparr();
        var obj = this.__data;
        while (pKpArr.length -1) {
            var tPath = pKpArr.shift();
            if (!obj[tPath]) {
                obj[tPath] = {};
            } else if (Array.isArray(obj[tPath])) {
                if (obj[tPath].length === 0) {
                    obj[tPath] = {};
                }
            }
            obj = obj[tPath];
        }
        obj[pKpArr[0]] = value;

        if (!skipChangeDetect) {
            this.__handleChange();
        }
    }

    __qpPatchReal(data, keypath, skipChangeDetect = false, deleteOrig = false) {
        this.__hydrate();

        let path_prefix = KewpiePath.coalesce(keypath);
        let updates;

        if (Array.isArray(data)) {
            data = new KewpieData(data);
        }

        if (data instanceof KewpieData) {
            updates = data.toKeypaths();
        } else {
            updates = this.constructor.__qpObjToKpArr(data);
        }


        for (var key in updates) {
            let pKey = key;
            let value = updates[pKey];
            if (path_prefix && !path_prefix.isNull) {
                pKey = `${path_prefix.toString()}.${pKey}`;
            }

            if (Array.isArray(value) || value instanceof KewpieData) {
                if (deleteOrig){
                    this.unset(keypath);
                }
                this.__qpPatchReal(value, pKey, skipChangeDetect, deleteOrig);
            } else {
                this.__qpSetReal(pKey, value, skipChangeDetect);
            }

        }

    }



    __clean(){
        this.__data = CleanObject(this.__data);
    }

    __encache(keypath, val){
        this.#cache[keypath] = val;
    }
    __decache(keypath) {
        Object.keys(this.#cache).forEach((key) => {
            if (key.indexOf(keypath) === 0) {
                delete this.#cache[key];
            }
        });
    }


    //// PUBLIC INTERFACE



    toKeypaths(keypath = null){
        var base = this.constructor.__qpObjToKpArr(this.data(keypath));
        return base; 
    }
    toKeypathArray(keypath = null) {
        return this.to_keypatharray(keypath);
    }

    to_keypatharray(keypath = null){
        let base = this.toKeypaths(keypath);
        let accum = [];
        for (var key in base) {
            accum.push({key, value: base[key]});
        }
        accum.sort((a, b) => {
            if (a.key < b.key) {
                return -1;
            }
            if (a.key > b.key) {
                return 1;
            }
            return 0;
        });
        return accum;
    }


    __originate(data) {
        if (this.__qp_original) {
            throw new KewpieInvalidOperationException("Cannot originate twice");
        }

        this.__qp_original = data;
        let flat = this.constructor.__qpObjToKpArray(data);

        let conditionals = [];
        for (var ii=0; ii < flat.length; ii++) {
            let $value = flat[ii];
            let kp = new KewpiePath($value['key']);

            if (kp.endsWith(KewpieData.KEYS.CONDITIONALS)) {
                conditionals.push(kp);
            }
            this.__qpSetReal(kp, $value['value'], true);
        }
        this.__kewpieConditionals = new KewpieConditionalCollection();       

        for (var ii=0; ii < conditionals.length; ii++) {
            this.__kewpieConditionals.add(
                conditionals[ii].name, this, conditionals[ii]
            );
        }


    }



    //// ARRAY-LIKE

    // NOTE: Hashes are not currently guaranteed across platforms.
    // We can handle this in the future... for now, the system does an MD5
    // of JSON.stringify.  JSON objects are not guaranteed to be serialize
    // across platforms because of... JSON.
    //
    // HOWEVER -- we should be able to build our own hasing algorithm based on
    // conversion to a keypath array, and then hashing that.
    hash(keypath = null) {
        let dat;
        if (keypath) {
            dat = this.first(keypath);
        } else {
            dat = this.__data
        }
        return KewpieHash.hash(dat);
    }

    // TODO : Verify that this is looking for a 
    // numeric-only array, or is an implementation
    // vagary between JS and PHP.
    isArray(keypath = null){
        let val = this.get(keypath);
        return Array.isArray(val);
    }
  


    //// OBSERVE/EVENTS
    observe(keypath, fn, idx, order=100, immediate = true) {
        this.events.observe(keypath, fn, idx, order)

        // if (!this.#observeCache) {
        //     this.#observeCache = {};
        // }
        // let dat = this.first(keypath);

        // if (!this.#observeCache[keypath]) {
        //     this.#observeCache[keypath] = {
        //         hash: this.hash(keypath),
        //         keypath: keypath,
        //         listeners: [], 
        //         oldValue: Clone(dat)
        //     }
        // }
        // this.#observeCache[keypath].listeners.push({fn, idx});

        if (immediate) {
            let evt = new KewpieDataEvent({
                type: KewpieEvent.TYPES.OBSERVER,
                keypath: keypath,
                targetPath: keypath,
                value: this.first(keypath), 
                oldValue: null,
                hash: this.hash(keypath),
                oldHash: null,
            });
            fn(evt);    
        }
    }

    unobserve(idx) {
        this.events.unobserve(idx);
        // if (!this.#observeCache) return;
        // if (this.#observeCache[keypath]) {
        //     let listeners = this.observeCache[keypath].listeners;
        //     for (var ii=0; ii < listeners.length; ii++) {
        //         if (listeners[ii].idx == idx) {
        //             listeners.splice(ii, 1);
        //             break;
        //         }
        //     }
        // }
    }

    // clearObservers(keypath) {
    //     if (!this.#observeCache) return;
    //     delete this.#observeCache[keypath];
    // }

    // Keypath is set, and we'll use it to do a match against observeCache
    // in the future.
    checkObservers(keypath) {
        // if (!this.#observeCache) return;
        let observers = this.#dispatcher.getAllObservers();
        for (var path in observers) {

            let ref = observers[path];
            let newHash = this.hash(ref.keypath);

            if (newHash !== ref.hash) {
                               
                let val = Clone(this.first(ref.keypath));
                let evt = new KewpieDataEvent({
                    type: KewpieEvent.TYPES.OBSERVER,
                    keypath: ref.keypath,
                    targetPath: keypath,
                    value: val, 
                    oldValue:  ref.oldValue,
                    hash: newHash,
                    oldHash: ref.hash,
                });

                // let evt = new KewpieEvent({
                //     value: Clone(val), 
                //     path: ref.keypath, 
                //     actualPath: keypath, 
                //     oldValue: ref.oldValue,
                //     hash: newHash,
                //     previousHash: ref.hash,
                // });

                // console.log(this.hash(ref.keypath));


                // ref.listeners.forEach(({fn}) => {
                //     if (!evt.isPropagationStopped) {
                //         fn(evt);
                //     }
                // });
                this.events.updateObserversHash(path, newHash);
                this.events.updateObserversValue(path, val);
                this.events.dispatch(evt);
            }
        }
    }

    __handleChange(keypath) {
        this.#cache ={};
        this.__clean();
        this.checkObservers(keypath);
    }


}

KewpieData.KEYS = new EnumMap('string', {
    CONDITIONALS: "::CONDITIONALS",
    LANGREF: "::LANGPATCH",
    ENVREF: "::ENVPATCH",
});

export default KewpieData;

import KewpieException from "./KewpieException.js";

class KewpiePathInvalidTypeException extends KewpieException {
    constructor(message){
        super(message);
        this.name = "KewpiePathInvalidTypeException";
    }
}

export default KewpiePathInvalidTypeException;
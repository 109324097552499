import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import EasStrings from '../Common/EasStrings.js';
import AbstractEasComponent from '../Common/AbstractEasComponent';


class AbstractFormBase extends AbstractEasComponent {
    #uuid;
    #flags = [];
    #value;
    #setVal;

    constructor(props) {
        super(props);
        
        if (props.def) {
            if (props.def.flags) {
                this.#flags = props.def.flags;

                if (this.#flags.indexOf('required') > -1) {
                    this.setModifier('is', 'required', false);
                }
            }
            for (var key in this.props.def.modifiers) {
                this.setModifier(key, this.props.def.modifiers[key], false);
            }
        }

        if (props.modifiers) {
            for (var key in props.modifiers) {
                this.setModifier(key, props.modifiers[key], false);
            }    
        }


        // this.ref = null;
        this.hook();
    }

    componentDidMount(){
        if (this.parent) {
            this.derivedValue = this.value;            
            // let value = this.parent.getValue(this.identifier);
            // if (value) {
            //     this.setState({value: this.parent.getValue(this.identifier)});
            //     this.#value = value;
            // }
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
            this.derivedValue = this.value;
        }
    }


    get uuid(){
        if (!this.#uuid) {
            this.#uuid = `el_${uuidv4()}`;
        }
        return this.#uuid;
    }

    get pattern(){
        return null;
    }
    get parent(){
        return this.props.parent;
    }

    get flags(){
        return this.#flags;
    }

    get identifier(){
        return (this.props.def && this.props.def.datakey) || null;
    }
    // get value(){
    //     return this.#value;
    //     return this.state.value ?? null;
    // }

    get def(){
        return this.props.def ?? null
    }
    get lookup(){
        return this.props.lookup ?? null
    }

    buildEvent(){
        let dat = {
            value: this.value
        }
        if (this.identifier) {
            dat.identifier = this.identifier;
        }
        if (this.def) {
            dat.def = this.def;
        }
        if (this.ref) {
            dat.ref = this.ref;
        }
        if (this.lookup) {
            dat.lookup = this.lookup;
        }

        let evt = new CustomEvent('input',{
            detail: dat
        });
        return evt; 
    }

    set derivedValue(value) {
        this.#value = value;
        if (!this.#setVal) {
            this.setState({value});
        }
    }

    set value(value){
        this.#setVal = value;
        this.setState({value});

        let evt = this.buildEvent();

        if (this.parent && this.parent.events) {
            this.parent.events.fire('input', evt);            
        }
        if (this.props.onInput) {
            this.props.onInput(evt);
        }
    }

    get value() {
        if (this.#setVal) {
            return this.#setVal;
        }
        if (this.#value) {
            return this.#value;
        }

        if (this.parent) {
            this.#value = this.parent.getValue(this.identifier);
        }
        if (!this.#value) {
            this.#value = this.props.value ?? this.props.def.value ?? null;
        }

        return this.#value;
    }


    // set valueInitial(value) {
    //     console.log(`${this.type} - setting initial value: ${value}`);
    //     this.#value = value;
    //     this.state.value = value;
    // }

    hasFlag(flag){
        return this.#flags.indexOf(flag) > -1;
    }
    hook(){}


    get inputAtts() {
        let inputAtts = this.props.def.input_atts ?? {};
        inputAtts.placeholder = this.paramString('placeholder');
        inputAtts.required = this.hasFlag('required');
        return inputAtts;
    }

    toggleFlag(flag) {
        if(this.#flags.includes(flag)) {
            this.removeFlag(flag)
        } else {
            this.setFlag(flag);
        }
    }

    setFlag(flag) {
        if(!this.#flags.includes(flag)) {
            this.#flags.push(flag);
        }
    }

    removeFlag(flag) {
        if(this.#flags.includes(flag)) {
            this.#flags = this.#flags.filter( current_flag => flag != current_flag)
        }
    }
}

export default AbstractFormBase;

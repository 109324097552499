
import KewpieException from "./KewpieException.js";

class KewpieInvalidOperationException extends KewpieException {
    constructor(message){
        super(message);
        this.name = "KewpieInvalidOperationException";
    }
}

export default KewpieInvalidOperationException;
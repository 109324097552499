import { DeepMerge } from "../../../eas-node-util";
import React, { Component } from 'react';
import Kewpie from "../../../kewpie";

/** 
 * Note:
 * It may make sense to build a string renderer, so that we can handle things like <p> in a sane way.
 */

class GlobalStringMap {
 
    #stringmap = {};
    #current_language;
    #default_language = 'en';
    #kp;

    constructor(stringmap){
        this.#stringmap = stringmap;
        this.#current_language = 'en';
    }

    static get instance(){
        if (!GlobalStringMap.__instance) {
            GlobalStringMap.__instance = new GlobalStringMap({});
        }
        return GlobalStringMap.__instance;
    }

    addStrings(lang, stringmap) {
        this.#stringmap = DeepMerge(this.#stringmap, {[lang]: stringmap});
        this.#kp = null;
    }

    get kp(){
        if (!this.#kp) {
            this.#kp = Kewpie.from_array(this.#stringmap);
        }
        return this.#kp;
    }

    decurly(text, fields) {
        let ucfields = Object.keys(fields).reduce((acc, k) => {
            acc[k.toUpperCase()] = fields[k];
            return acc;
        }, {});

        return text.replace(/{{(.*?)}}/g, (match, p1) => {
            return ucfields[p1] ?? '';
        });
    }

    curly(key, dat = {}) {
        let ret=this.get(key) ??"";
        ret = JSON.parse(this.decurly(JSON.stringify(ret), dat));
        return ret;
    }

    renderHtml(key, obj, asJsx = false, data = {}) {
        if (typeof obj == 'string') {
            let val=this.decurly(obj, data);
            if (asJsx) {
                return (<div dangerouslySetInnerHTML={{__html:val}}></div>);
            } else {
                return val;
            }
        }
        switch (obj['type'] ?? null) {
            case '__BULLETLIST':
                return (
                    <ul>
                        {obj.items.map((item, idx) => {
                            return (
                                <li key={idx}>
                                    {this.renderHtml(`${key}.items.${idx}`,item, true, data)}
                                </li>
                            )
                        })}
                    </ul>
                );
            case "__TEXTBLOCK":
            default:
                return (
                    <div className="text-display" key={key}>
                        {obj.eyebrow ? (
                            <div className="text-display__eyebrow">{this.decurly(obj.eyebrow, data)}</div>
                        ) : null}
                        {obj.header ? (
                            <h2 className="text-display__header">{this.decurly(obj.header, data)}</h2>
                        ) : null}
                        {obj.subheader ? (
                            <div className="text-display__subheader">{this.decurly(obj.subheader, data)}</div>
                        ) : null}
                        {obj.body ? this.curlyRender(`${key}.body`, data) : null}

                        {obj.disclaimer ? (
                            <div className="text-display__disclaimer">{this.decurly(obj.disclaimer, data)}</div>
                        ) : null}

                    </div>
                );

        }

    }

    curlyRender(key, data) {
        let val = this.get(key);
        if (!val) {
            console.log(`No string found for key: ${key}`);
            return key;
        }
        return this.renderHtml(key, val, true, data);
    }

    render(key, asJsx = false) {
        let val = this.get(key);
        if (!val) {
            console.log(`No string found for key: ${key}`);
            return key;
        }
        return this.renderHtml(key, val, asJsx);
    }

    get(key, orDef = false) {
        return this.kp.get(`${this.#current_language}.${key}`) ?? (orDef ? key : null);
    }

    get defaultLanguage(){
        return this.#default_language;
    }
    set defaultLanguage(lang){
        this.#default_language = lang;
        // Should probably fire an update when this happens.
    }

    get currentLanguage(){
        return this.#current_language ?? this.defaultLanguage;
    }
    set currentLanguage(lang){
        this.#current_language = lang;
        // Should probably fire an update when this happens.
    }
}

export default GlobalStringMap.instance;

import React, { Component } from 'react';
import AbstractFormBase from './AbstractFormBase';
import EasElementHelper from './EasElementHelper';
import GlobalStringMap from "../Common/GlobalStringMap.jsx";

class EasFormFieldSelect extends AbstractFormBase {

    constructor(props) {
        super(props);
        this.state.pattern = (this.props.atts && this.props.atts.pattern) ?? this.pattern ?? props.def.pattern ?? null
    }

    get classbase() {
        return 'form-control';
    }

    get type() {
        return this.props.def.subtype ?? 'select';
    }

    filterItems(items = [], addedInitial = null) {
        let ret = [];
        if (addedInitial) {
            ret.push(addedInitial);
        }
        for (var ii=0; ii < items.length; ii++) {
            ret.push(items[ii]);
        }
        if (this.props.def.extraItems) {
            for (var jj=0;  jj< this.props.def.extraItems.length; jj++) {
                let ref = this.props.def.extraItems[jj];
                ref.name = this.paramString(ref.name);
                ret.push(ref);
            }
        }        
        return ret;
    }


    get emptyItemText(){
        return this.props.emptyItemText ?? (this.props.def.atts && this.props.def.atts.emptyItemText) ?? '';
    }

    change(e){
        let val = e.target.value;
        if (val == '::none') { val = null };
        this.value = val;

        /*
        * Extend event handling to bind in additional info thatn just the raw value.
        */
        let evt = new CustomEvent('inputData',{
            detail: {
                identifier: this.identifier,
                val,
                extra: e.target.options[e.target.selectedIndex].dataset,
                ref: this.ref
            }
        });
        if (this.props.onInputData) {
            this.props.onInputData(evt);
        }

    }

    get items(){
        return this.props.def.options ?? [];
    }
    
    get addedFirstItem(){
        if (this.props.def.skipAddedSelector ?? false) {
            return null;
        }
        return {
            name : this.paramString(this.props.def.emptyText) ?? GlobalStringMap.get(this.props.def.emptyText, true) ?? 'Select one...',
            key: "",
            value:"",
            disabled : 'disabled',
            // selected : 'selected'
        }
    }

    getOptions(){
        let items = this.filterItems(this.items, this.addedFirstItem);
        return items.map((item, idx) => {
            // Handle OptGroup
            if (item.items ?? false) {

                let subitems = item.items.map((subitem, subidx) => {
                    let atts = {};
                    if (subitem.data) {
                        for (var key in subitem.data) {
                            atts[`data-${key}`] = subitem.data[key];
                        }
                    }

                    return (
                        <option 
                            key={subidx} 
                            {...atts}
                            value={subitem.key}
                            disabled={subitem.disabled ?? null}
                        >{subitem.name}</option>                    
                    )
                });
 
                return (
                    <optgroup key={idx} label={GlobalStringMap.get(item.name, true)}>
                        {subitems}
                    </optgroup>
                );

            } else {
                let atts = {};
                if (item.data) {
                    for (var key in item.data) {
                        atts[`data-${key}`] = item.data[key];
                    }
                }
                return (
                    <option 
                        key={idx} 
                        {...atts}
                        value={item.key}
                        selected={item.selected ?? null}
                        disabled={item.disabled ?? null}
                    >{item.name}</option>
                )
            }
        })
    }

    render() {
        this.ref = React.createRef();
        return (
            <div className={this.state.className}>
                <label 
                    className={this.getClass('label')} 
                    htmlFor={this.uuid}
                >
                    {this.paramString('label')}                
                    {this.hasFlag("required") && "*"}
                </label>

                <select
                    type={this.type}
                    className={this.getClass('input')} 
                    id={this.uuid} 
                    size={this.def.size ?? null}
                    placeholder={this.paramString('placeholder')} 
                    required={this.hasFlag('required')} 
                    defaultValue={this.state.value ?? ""}
                    onChange={this.change.bind(this)}
 
                >
                    {this.getOptions()}
                </select>

                {this.props.def.instructions ? (
                    <div 
                        className={this.getClass('instructions')}
                        dangerouslySetInnerHTML={{__html:this.paramString('instructions')}}
                    ></div>
                ) : null}

            </div>
        )
    }

}
EasElementHelper.addType('select', EasFormFieldSelect);
export default EasFormFieldSelect;

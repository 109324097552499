

function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}
  
function DeepMerge(target, source, concat_arrays = true) {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
        if (isObject(source[key])) {
            if (!(key in target)) {
                Object.assign(output, { [key]: source[key] });
            } else {
                output[key] = DeepMerge(target[key], source[key]);
            }
        } else if (concat_arrays && Array.isArray(source[key])) { 
            if (!(key in target)) { 
                output[key] = source[key]; 
            } else { 
                output[key] = output[key].concat(source[key]);
            }
        } else {
            Object.assign(output, { [key]: source[key] });
        }
        });
    }
    return output;
}
function Clone(obj, concat_arrays = true) {
    if (isObject(obj)) {
        return DeepMerge({}, obj, concat_arrays);
    }
    return obj;
}


export {
    DeepMerge,
    isObject,
    Clone
};
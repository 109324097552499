import React, { Component } from 'react';
import AbstractFormBase from './AbstractFormBase';
import EasElementHelper from './EasElementHelper';

class EasFormFieldColor extends AbstractFormBase {
    #working = false;
    #next = null;

    constructor(props) {
        super(props);
        this.state.value = (this.props.color)?? props.def.color ?? "red"
    }

    get classbase() {
        return 'form-control';
    }
    get type(){
        return 'color';
    }

    async onInput(evt) {
        let value = evt.target.value;

        let ret = new CustomEvent('input',{
            detail: {
                identifier: this.identifier,
                value,
                ref: this.ref
            }
        });

        if (this.props.onInput) {
            this.props.onInput(ret);
        }
    }
    
    async onChange(evt) {
        let value = evt.target.value;
        this.value = value; 
    }

    render() {
        this.ref = React.createRef();
        // console.log(this.props.def.presetColors);
        return (
            <div className={this.state.className}>
                <label 
                    className={this.getClass('label')} 
                    htmlFor={this.uuid}
                >
                    {this.paramString('label')}                
                </label>

                <input 
                    ref={this.ref}
                    list={this.props.def.presetColors ? `${this.uuid}__data` : null}
                    type="color"
                    className={this.getClass('input')} 
                    id={this.uuid} 
                    onInput={this.onInput.bind(this)}
                    onChange={this.onChange.bind(this)}
                    value={this.state.value}
                    defaultValue={this.state.value}
                    {...this.inputAtts}
                ></input>
                {this.props.def.presetColors ? (
                    <datalist id={`${this.uuid}__data`}>
                        {this.props.def.presetColors.map(function(color, idx){
                            return (
                                <option key={idx}>{color}</option>
                            )
                        }.bind(this))}
                    </datalist>
                ) : null}
                {this.props.def.instructions ? (
                    <div 
                        className={this.getClass('instructions')}
                        dangerouslySetInnerHTML={{__html:this.paramString('instructions')}}
                    ></div>
                ) : null}

            </div>
        )
    }
}
EasElementHelper.addType('color', EasFormFieldColor);

export default EasFormFieldColor;
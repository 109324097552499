import React, { ElementType } from "react";
import {Link} from "react-router-dom";
import AbstractComponent, {IStandardProps} from "./AbstractComponent";


/**
 * Multi-faced block string system for composing text.
 * 
 * Designed for interoperabilility with structured text coming from places
 * like headless CMS systems.  Most importantly, normalizes HTML structures of
 * almost many kinds of standard text blocks so that the CSS handles the styling
 * and layout, while the block is well-known and well-defined..
 */

export interface IEasBaseTextDescriptor {
    tag? : ElementType,    
    props?: any 
}
export interface IEasTextBullets {
    bullets: string[]
}
export type IEasTextFieldSupported = string|Array<string|IEasTextBullets>;

export interface IEasDirectTextDescriptor extends IEasBaseTextDescriptor {
    text: IEasTextFieldSupported,
}
//! Not currently used.
export interface IEasLookupTextDescriptor extends IEasBaseTextDescriptor {
    "LOOKUP::text": string,
}

export interface IEasIcon {
    src: string,
    alt?: string
}

// TODO! - add support for closures
export type IEasButton = {
    as:"button",
    text?: string,
    action?: string,
    closure?: Function,
    icon?: IEasIcon | JSX.Element,
    iconPosition?: "before"|"after"
}

export type IEasLink = {
    as:"link",
    text: string,
    href: string,
    target?: "_blank"|"self"|"parent"|"top",
    rel?: string,
    icon?: IEasIcon | JSX.Element,
    iconPosition?: "before" | "after",
    textBefore?: string,
    textAfter?: string
}

export type IEasAction = IEasButton | IEasLink;

export interface IEasComposedText extends IStandardProps {
    as? : ElementType,
    eyebrow?: string|IEasDirectTextDescriptor,
    header?: string|IEasDirectTextDescriptor,
    subheader?: string|IEasDirectTextDescriptor,
    subsubheader?: string|IEasDirectTextDescriptor,
    body?: string|IEasDirectTextDescriptor,
    disclaimer?: string|IEasDirectTextDescriptor,
    actions?: IEasAction[],
    [key: string]: any
}

export type IEasMediaImage = IStandardProps &{
    type: "image",
    src: string,
    alt: string
    width?: number,
    height?: number,
    loading?: "lazy"|"eager"
}

//! TODO - add more video field support.
export type IEasMediaVideo = IStandardProps &{
    type: "video",
    src: string,
    alt: string
    width?: number,
    height?: number
}

export type IEasMediaSVG = IStandardProps &{
    type: "svg",
    content: string
}

export type IEasMedia = IEasMediaImage | IEasMediaVideo | IEasMediaSVG;

export type IEasComposedContent = IStandardProps & {
    text?: IEasComposedText,
    media?: IEasMedia,
    postText?: string | IEasDirectTextDescriptor
}


class ComposedContent extends AbstractComponent<IEasComposedContent> {

    // constructor(props:IEasComposedText){
    //     super(props);
    // }

    get classbase(){
        return 'textcontent';
    }

    /**
     * Handles a variety of structures of text fields, include strings, arrays of strings,
     * arrays of strings and bullets.
     * 
     * Polymorphic renderer that allows some support for changing the tagname,
     * which is *very* important for semantic HTML and accessibility.
     */
    renderField(key:string, idx?:number, isStructured:boolean = false){
        let local = Object.assign({}, this.props.text) as IEasComposedText;
        if (local.hasOwnProperty(key)) {
            let val = local[key] as IEasDirectTextDescriptor;
            let Tag = val.tag ?? 'div';


            let refText = null;
            if (typeof val === 'string') {
                refText = val;
            }

            // Simple string.
            if (typeof val.text === 'string') {
                refText = val.text;
            }

            if (refText) {
                let props:any = {
                    className: this.getClass(key),
                };

                return (
                    <Tag key={key} {...props}
                        dangerouslySetInnerHTML={{__html: refText}}
                    ></Tag>
                );
            }

            let ret = [];
            for (var ii=0; ii < val.text.length; ii++) {
                let obj = val.text[ii];
                if (typeof obj === 'string') {
                    // do paragraphs by default in an array.
                    Tag = val.tag ?? 'p';
                    let props:any = {
                        className: this.getClass(key),
                        key: `${key}-${ii}`
                    };

                    ret.push( (
                        <Tag key={ii} {...props}
                            dangerouslySetInnerHTML={{__html: obj}}
                        ></Tag>
                    ) );
    
                // special handling for bulleted list structures.
                } else if (obj.hasOwnProperty('bullets')) {
                    ret.push( (
                        <ul className={this.getClass(key)} key={`${key}-${ii}`}>
                            {(obj.bullets as string[]).map((item, idx) => {
                                return (
                                    <li key={idx} className={this.getClass(key, 'item')}
                                        dangerouslySetInnerHTML={{__html: item}}
                                    ></li>
                                );
                            })}
                        </ul>
                    ) );
                }
            }
            return <div key={key}>
                {ret.map((item, idx) => {
                    return item;
                })}
            </div>;
        }
    }


    getFields(){
        let ref = ['eyebrow', 'header', 'subheader', 'subsubheader', 'body', 'disclaimer'];

        let ret = [];
        for (var key of ref) {
            if (this.props.text?.hasOwnProperty(key)) {
                ret.push(this.renderField(key));
            }
        }
        return ret;
    }

    //! TODO - improve this.
    getMedia(){
        let wrapperProps;

        switch (this.props.media?.type) {
            case 'image':
                let imgProps = {
                    src: this.props.media.src,
                    alt: this.props.media.alt ?? '',
                    loading: this.props.media.loading || 'lazy',
                };
                wrapperProps = {
                    className: this.cm.getModifiedClass({'type':['image']},'media'),
                }

                return (
                    <div {...wrapperProps}>
                        {/* eslint-disable-next-line */}
                        <img {...imgProps} />
                    </div>
                )
            case 'svg':
                wrapperProps = {
                    className: this.cm.getModifiedClass({'type':['svg']},'media'),
                }                
                return (
                    <div {...wrapperProps} dangerouslySetInnerHTML={{__html: this.props.media.content}}></div>
                );
            case 'video':
                wrapperProps = {
                    className: this.cm.getModifiedClass({'type':['video']},'media'),
                }

                let vidProps = {
                    src: this.props.media.src,
                    alt: this.props.media.alt ?? '',
                    className: this.cm.getModifiedClass({'type':['video']},'media'),
                };

                return (
                    <div {...wrapperProps}>
                        <video {...vidProps}>

                        </video>
                    </div>
                )

        }
    }

    handleAction(e:React.MouseEvent, action:IEasButton){
        // Todo - allow injection of closure
        if (action.closure) {
            action.closure(e);
        }
    }

    buildLink(action: IEasAction, idx: number) {
        let Tag = action.as === 'button' ? 'button' : 'a';
        let props: any = {
            className: this.getClass('action'),
            onClick: action.as === 'button' ? (e: React.MouseEvent) => this.handleAction(e, action as IEasButton) : undefined,
            href: action.as === 'link' ? action.href : undefined,
            target: action.as === 'link' ? action.target : undefined,
            rel: action.as === 'link' ? action.rel : undefined,
        };
    
        const content = [];
    
        const renderIcon = (icon: IEasIcon | JSX.Element) => {
            let clsName = this.cm.getModifiedClass({"pos" : [action.iconPosition === 'before' ? 'before': "after"]},'action', 'icon');
            if ('src' in icon) {  // This checks if the icon is of type IEasIcon
                return <img 
                    className={clsName}
                    key="icon" src={icon.src} alt={icon.alt || ''} 
                />;
            } else {
                return (
                    <span key="icon" className={clsName}>
                        {icon}
                    </span>
                );  // This assumes the icon is already a JSX.Element and can be rendered directly
            }
        };
    
        if (action.icon && action.iconPosition === 'before') {
            content.push(renderIcon(action.icon));
        }
    
        content.push(
            (
                <span className={this.getClass('action', 'text')}>{action.text}</span>
            )
        );
    
        if (action.icon && action.iconPosition === 'after') {
            content.push(renderIcon(action.icon));
        }
    
        return (
            <Tag key={`action-${idx}`} {...props}>
                {content}
            </Tag>
        );
    }

    convertToString(postText?: string | IEasDirectTextDescriptor): string {
        if (!postText) return '';
    
        if (typeof postText === 'string') {
            return postText;
        } else if (Array.isArray(postText.text)) {
            return postText.text
                .map((item) =>
                    typeof item === 'string'
                        ? item
                        : item.bullets.join('<br>')
                )
                .join('<br>');
        } else {
            return postText.text || '';
        }
    }

    getActions(){
        if (this.props.text?.hasOwnProperty('actions')) {
            let local:IEasAction[]|undefined = (this.props.text as IEasComposedText).actions || [];
            if (!local) { return null; }

            return (
                <div className={this.getClass('actions')}>
                    {local.map((action, idx) => {
                        return this.buildLink(action, idx);
                    })} 
                </div>
            )
        }
        return null;
    }

    render(){
        return (
            <div className={this.state.className}>
                {this.getMedia()}
                <div className={this.getClass('text')}>
                    {this.getFields()}
                </div>
                {this.getActions()}

                {this.props.postText && (
                <div className={this.getClass( 'posttext')}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.convertToString(this.props.postText),
                        }}
                    />
                </div>
            )}
            </div>
        );
    }
}

export default ComposedContent;


import React, { Component } from 'react';
import AbstractFormBase from './AbstractFormBase';
import EasElementHelper from './EasElementHelper';

class EasFormFieldCheckboxGroup extends AbstractFormBase {

    constructor(props) {
        super(props);
        if (!this.state.value) {
            this.valueInitial = (this.props.def && this.props.def.defaultValue) || [];
        }
        if(this.hasFlag("required")) {
            this.required = true;
        }

    }

    get classbase() {
        return 'form-checkboxgroup';
    }

    // async onInput(evt) {
    //     let value = evt.target.value;
    //     this.value = value;
    // }

    onItemChange() {
        if (this.ref && this.ref.current) {
            let accum = [];
            let checks = this.ref.current.querySelectorAll(':checked');
            for (var ii = 0; ii < checks.length; ii++) {
                accum.push(checks[ii].value);
            }
            this.value = accum;
            if(this.required) {
                if(accum.length > 0) {
                    this.removeFlag("required");
                } else {
                    this.setFlag("required");
                }
            }
        }
    }

    render() {
        if (!this.ref) {
            this.ref = React.createRef();
        }

        return (
            <div className={this.state.className} ref={this.ref}>
                {this.props.def.headline ? (
                    <div className={this.getClass('headline')} dangerouslySetInnerHTML={{ __html: this.props.def.headline }}></div>
                ) : null}
                {this.props.def.preamble ? (
                    <div className={this.getClass('preamble')}>{this.props.def.preamble}</div>
                ) : null}


                {this.props.def.sectioned_options && this.props.def.sectioned_options.map((section) => {
                    return (
                        <div key={section.title} className={this.getClass("item-group")}>
                            <h4 className={this.getClass("item-group__title")}>{section.title}</h4>
                            <div className={this.getClass("items")}>
                                {section.options.map((item) => {
                                    let checked = this.state.value && Array.isArray(this.state.value) && this.state.value.indexOf(item.value) !== -1;
                                    return (
                                        <div key={item.value} className={this.getClass('item')}>
                                            <input
                                                type="checkbox"
                                                id={this.uuid + '-' + item.value}
                                                value={item.value}
                                                defaultChecked={checked}
                                                onChange={() => { this.onItemChange() } }
                                                disabled={item.disabled}
                                                {...this.inputAtts}
                                            ></input>
                                            <label htmlFor={this.uuid + '-' + item.value}
                                                dangerouslySetInnerHTML={{ __html: item.label }}
                                            ></label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
                {!this.props.def.sectioned_options && this.props.def.options.map((item) => {
                        let checked = this.state.value && Array.isArray(this.state.value) && this.state.value.indexOf(item.value) !== -1;
                        return (
                        <div className={this.getClass('items')}>
                            <div key={item.value} className={this.getClass('item')}>
                                <input
                                    type="checkbox"
                                    id={this.uuid + '-' + item.value}
                                    value={item.value}
                                    defaultChecked={checked}
                                    onChange={() => { this.onItemChange() } }
                                    {...this.inputAtts}
                                ></input>
                                <label htmlFor={this.uuid + '-' + item.value}
                                    dangerouslySetInnerHTML={{ __html: item.label }}
                                ></label>
                            </div>
                        </div>
                        )
                })}
                {this.props.def.instructions ? (
                    <div
                        className={this.getClass('instructions')}
                        dangerouslySetInnerHTML={{ __html: this.paramString('instructions') }}
                    ></div>
                ) : null}

            </div>
        )
    }
}
EasElementHelper.addType('checkboxgroup', EasFormFieldCheckboxGroup);
export default EasFormFieldCheckboxGroup;

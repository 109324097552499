import React, { Component } from 'react';
import AbstractFormBase from './AbstractFormBase';
import EasElementHelper from './EasElementHelper';
class EasFormToggleButton extends AbstractFormBase {

    constructor(props) {
        super(props);
        this.setModifier('toggled', 'off', false);        
    }

    get classbase() {
        return 'form-togglebutton';
    }

    onInput(e){
        let val = e.target.checked;
        this.value = val;
        this.setModifier('toggled', val ? 'on' : 'off');
    }

    render() {

        return (
            <div className={this.state.className}>
                <div 
                    className={this.getClass('label')} 
                    htmlFor={this.uuid}
                >
                    {this.paramString('label')}                
                </div>


                <div className={this.getClass('toggler')}>
                    
                    {
                        this.props.def.optionLeft ? (
                            <div className={this.getClass('toggler', 'option','left')}>{this.paramString('optionLeft')}</div>
                        ) : null
                    }
                    <div className={this.getClass('toggler','wrapper')}>
                        <input  
                            type="checkbox"
                            className={this.getClass('toggler','check')} 
                            id={this.uuid} 
                            onInput={this.onInput.bind(this)}
                            placeholder={this.paramString('placeholder')} 
                            required={this.hasFlag('required')}
                        ></input>
                        <label className={this.getClass('toggler', 'background')} htmlFor={this.uuid}>
                            <div className={this.getClass('toggler', 'background','active')}></div>
                        </label>
                        <label className={this.getClass('toggler', 'foreground')} htmlFor={this.uuid}>
                            <div className={this.getClass('toggler', 'foreground','indicator')}></div>
                        </label>
                    </div>
                    {
                        this.props.def.optionRight ? (
                            <div className={this.getClass('toggler', 'option','right')}>{this.paramString('optionRight')}</div>
                        ) : null
                    }

                </div> 

                {this.props.def.instructions ? (
                    <div className={this.getClass('instructions')}>
                        {this.paramString('instructions')}                    
                    </div>
                ) : null}

                {/* {this.state.error ? (
                    <div 
                        className={this.getClass('error')} 
                    >
                        {this.paramString('error')}
                    </div>
                ) : null} */}
            </div>
        )
    }

}
EasElementHelper.addType('togglebutton', EasFormToggleButton);
export default EasFormToggleButton;
import React, { Component } from 'react';
import AbstractFormBase from './AbstractFormBase';
// import EasFormFieldButton from './EasFormFieldButton.js';
import EasElementHelper from './EasElementHelper';

const blankImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

class EasFormFieldImage extends AbstractFormBase {
    #status;

    constructor(props) {
        super(props);

        this.setModifier('empty', true, false);

        this.state = {
            className : this.getRootClass(),
            imagedata : blankImage,
            buttonText: this.props.def.status.empty.button ?? ''
        };         
    }
 
    get status() {
        return this.#status;
    }
    set status(val) {
        this.#status = val;
        if (val == 'empty') {
            this.setModifier('empty', true);
        } else {
            this.unsetModifier('empty');
        }
        //TODO - look this up against the string map.
        let buttonText = this.props.def.status[val].button ?? '';
        if (buttonText) {
            this.setState({'buttonText': buttonText});
        }
    }

    get classbase() {
        return 'form-image';
    }

    get type() {
        return 'image';
    }

    fileLoaded(result){
        this.value = result;
    }

    loadFile(event) {
        this.status = 'empty';
        this.setModifier('loading', true);
        this.setState({
            imagedata: blankImage
        });
    
        var reader = new FileReader();
        reader.onload = function(){
            // This is going to cause 3 state chages.  I think it is async
            // in which case it is fine... otherwise, should probably change.
            this.status = 'default';
            this.unsetModifier('loading');
            this.fileLoaded(reader.result);
        }.bind(this);
        reader.readAsDataURL(event.target.files[0]);
    }

    render() {

        return (          
            <div className={this.state.className}>

                <div className={this.getClass('bar')}>
                    <div className={this.getClass('headline')}>
                        {this.paramString('headline')}                
                    </div>

                    <label htmlFor={this.uuid} className={this.getClass('label')}>
                        {this.state.buttonText}
                    </label>

                </div>

                <input 
                    id={this.uuid}
                    className={this.getClass('input')}
                    type="file" 
                    accept="image/*" 
                    onChange={this.loadFile.bind(this)} 
                />
                <div className={this.getClass('preview','wrapper')}>
                    <img className={this.getClass('preview')} src={this.state.value} />
                </div>

                {this.props.def.instructions ? (
                    <div className={this.getClass('instructions')}>
                        {this.paramString('instructions')}                    
                    </div>
                ) : null}

            </div>
        )
    }

}
EasElementHelper.addType('image', EasFormFieldImage);
export default EasFormFieldImage;
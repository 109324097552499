import React from 'react';
import './App.scss';
import Hero from "./Components/Hero";
import EventsBlock from './Components/EventsBlock';
import InsightBlock from './Components/InsightBlock';
import SessionsBlock from "./Components/SessionsBlock";
import HappyHour from "./Components/HappyHour";
import Experts from "./Components/Experts";
import FormModal from "./Components/FormModal";
import Footer from './Components/Footer';

function App() {

    const [showForm, setShowForm] = React.useState(false);
    window.addEventListener('keydown', (ev) => { 
        if(ev.key === 'Escape') {
            setShowForm(false);
        }
    })

    return (
        <div className="App">
            <Hero/>
            <main>
            <EventsBlock onRegister={() => setShowForm(true) }/>
            <InsightBlock onRegister={() => setShowForm(true) }/>
            <HappyHour onRegister={() =>  setShowForm(true) }/>
            <SessionsBlock onRegister={() => setShowForm(true) }/>
            <Experts/> 
            {showForm && <FormModal onClose={() => setShowForm(false) }/> }
            </main>
            <Footer/>
        </div>
    );
}

export default App;

import React, { MouseEventHandler } from 'react';
import AbstractComponent, { IStandardProps } from "../Shared/AbstractComponent";
import { EasForm } from "../eas-react-forms-kewpie/lib/index";
import form_def from "./form-def.json";
import ContactInfo from "../Data/non-marketing.json";
import {DismissFilled } from '@fluentui/react-icons';

form_def.fields[4].options = ContactInfo.countries;

export type IFormModal = IStandardProps & {
    onClose: MouseEventHandler
}

enum formState {
    standard,
    closed,
    submitting,
    error,
    success
}

class FormModal extends AbstractComponent<IFormModal> {

    constructor(props: IFormModal) {
        super(props);
        this.state.formState = formState.standard;
        this.onAction = this.onAction.bind(this);
    }

    get classbase() {
        return 'form-modal';
    }

    async onAction(evt: any){

        const submission_data = evt.detail.value;
        submission_data.eventKey = "finops_barcelona_nov_2024";

        if (evt.type === 'submit' && this.state.formState === formState.standard){   

            this.setFormState(formState.submitting);

            const res = await fetch("/api/formSubmit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(submission_data)
            })

            if(res.ok){
                this.setFormState(formState.success);
            } else {
                this.setFormState(formState.error);
            }
        } else {
            this.setFormState(formState.error);
        }
    }

    setFormState(state : formState) {
        this.setState({ formState : state });
    }

    render() {
        return (
            <section className={this.state.className} role="dialog" aria-modal="true">
                <div className={this.getClass('inner')}>
                    <div className={this.getClass('content')}>
                        <button className={this.getClass('close')} aria-label="Close form" onClick={this.props.onClose}><DismissFilled /></button>
                        {this.state.formState == formState.standard && 
                            <EasForm
                                def={form_def}
                                onAction={this.onAction}
                            />
                        }
                        {this.state.formState === formState.closed &&
                            <div className={`${this.getClass('success')} eas-form`}>
                                <h2 className="eas-form__title">Registration is closed</h2>
                                <div className="eas-form__preamble">We hope we saw you at the event. Look out for more events from FinOps X in the future.</div>
                            </div>
                        }
                        {this.state.formState === formState.submitting &&
                            <div className={`${this.getClass('success')} eas-form`}>
                                <h2 className="eas-form__title">Submitting...</h2>
                            </div>
                        }
                        {this.state.formState === formState.error &&
                            <div className={`${this.getClass('success')} eas-form`}>
                                <h2 className="eas-form__title">There was an error with your registration</h2>
                                <div className="eas-form__preamble">Please try again and contact <a href="mailto: jens@easci.com">support</a> for help if this continues to happen</div>
                            </div>
                        }
                        {this.state.formState === formState.success &&
                            <div className={`${this.getClass('success')} eas-form`}>
                                <h2 className="eas-form__title">Thank you for registering to join the Microsoft activities at FinOps X Europe!</h2>
                                <div className="eas-form__preamble">Due to high demand and limited capacity, we are currently reviewing applications for our events. We appreciate your interest and will update you on your application status via email within approximately 48 hours.</div>
                            </div>
                        }
                    </div>

                </div>

            </section>
        )
    }
}

export default FormModal;

import React, { Component } from 'react';
import AbstractFormBase from "./AbstractFormBase";
import EasElementHelper from "./EasElementHelper";

class EasFormFieldset extends AbstractFormBase {
    // #fieldMap = {};

    constructor(props) {
        super(props);
    }

    get classbase() {
        return 'form-fieldset';
    }
    
    get type(){
        return null;
    }
    // buildElement({ field, idx} = {}){
    //     let el = EasElementHelper.buildElement({
    //         field, idx, parent: this.props.parent
    //     });
    //     this.#fieldMap[field.key] = {
    //         field: field,
    //         el: el,
    //         rel: this
    //     };
    //     return el;
    // }
    // get fields(){
    //     return this.#fieldMap;
    // }


    render(){
        let ref = React.createRef();
        return (
            <fieldset ref={ref} className={this.state.className}>
                <legend className={this.getClass('legend')}>
                    {this.props.def.headline}
                </legend>
                {this.props.def.preamble ? (
                    <div className={this.getClass('preamble')}>{this.props.def.preamble}</div>
                ):null}                
                <div className={this.getClass('elements')}>
                    {this.props.def.fields.map((field, idx) => {
                        return EasElementHelper.buildElement({
                            field, 
                            idx:field.datakey ?? field.key ?? idx, 
                            value: field.value,
                            parent: this.props.parent});
                    })}
                </div>
            </fieldset>
        )
    }

}

EasElementHelper.addType('fieldset', EasFormFieldset);
export default EasFormFieldset;
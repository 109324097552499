import React, { Component } from 'react';
import AbstractFormBase from './AbstractFormBase';
import EasElementHelper from './EasElementHelper';

class EasFormFieldMonth extends AbstractFormBase {

    constructor(props) {
        super(props);
    }

    get classbase() {
        return 'form-control';
    }
    get type(){
        return 'month';
    }

    async onInput(evt) {
        let value = evt.target.value;
        this.value = value;
    }
    onChange(evt) {}
    
    get inputAtts() {
        let inputAtts = this.props.def.input_atts ?? {};
        inputAtts.placeholder = this.paramString('placeholder');
        inputAtts.required = this.hasFlag('required');
        return inputAtts;
    }

    render() {
        this.ref = React.createRef();
        return (
            <div className={this.state.className}>
                <label 
                    className={this.getClass('label')} 
                    htmlFor={this.uuid}
                >
                    {this.paramString('label')}                
                </label>

                <input 
                    ref={this.ref}
                    type="month"
                    className={this.getClass('input')} 
                    id={this.uuid} 
                    onInput={this.onInput.bind(this)}
                    onChange={this.onChange.bind(this)}
                    defaultValue={this.state.value}
                    {...this.inputAtts}
                ></input>
                {this.props.def.instructions ? (
                    <div 
                        className={this.getClass('instructions')}
                        dangerouslySetInnerHTML={{__html:this.paramString('instructions')}}
                    ></div>
                ) : null}

            </div>
        )
    }
}
EasElementHelper.addType('month', EasFormFieldMonth);
export default EasFormFieldMonth;

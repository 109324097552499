import KewpieEvent from "../Events/KewpieEvent.js";

class KewpieConditionEvent extends KewpieEvent {
    #conditional;
    #qp;

    constructor(conditional, qp){
        super();
        this.#conditional = conditional;
        this.#qp = qp;
    }

    serialize(){
        return {
            value: this.value,
            type: this.type,
            state: this.state
        }
    }

    get value(){
        return this.#conditional.value;
    }
    get state(){
        return this.#conditional.current;
    }
    get meta(){
        return this.#conditional.meta;
    }
    get kewpie(){
        return this.#qp;
    }
    get type(){
        return KewpieEvent.TYPES.CONDITIONAL;
    }

}

export default KewpieConditionEvent;
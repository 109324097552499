import React, { Component } from 'react';
import EasFormFieldSelect from './EasFormFieldSelect';
import EasElementHelper from './EasElementHelper';
import GlobalStringMap from "../Common/GlobalStringMap.jsx";


class EasFormFieldAjaxSelect extends EasFormFieldSelect {

    constructor(props){
        super(props);
        this.setModifier('subtype', 'ajax', false);
    }

    componentDidMount(){
        this.loadAjax();
    }

    get endpoint(){
        // ?lang=${GlobalStringMap.currentLanguage}
        let base = this.props.endpoint ?? this.props.def.endpoint ?? null;
        if (base) {
            let urlbase = base.indexOf('http') == 0 ? undefined : window.location.origin;
            let url = new URL(base, urlbase);
            if (this.props.def && this.props.def.injectStringMapLang) {
                url.searchParams.append('lang', GlobalStringMap.currentLanguage);
            }
            return url.toString();
        }
        return base;


        // return this.props.endpoint ?? this.props.def.endpoint ?? null;
    }

    loadAjax(){
        if (!this.state.items) {
            let url = this.endpoint;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    this.setState({items: data.data});
                });
        }
    }

    get items(){
        return this.state.items ?? [];
    }

}

EasElementHelper.addType('ajax-select', EasFormFieldAjaxSelect);
export default EasFormFieldAjaxSelect;

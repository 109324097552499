import React from 'react';


/** Polymorphic router */
const Poly = ({def, keyRef=null, parent=null, atts={}, as='div', onInput=null, value=null, extra={}}) => {
    const Tag = as;
    return (
        <Tag key={keyRef} parent={parent} onInput={onInput} value={value} {...extra} {...atts} def={def}>
        </Tag>
    );
}

let map = {}

class EasElementHelper {

    static addType(type, ClassHandler) {
        map[type] = ClassHandler;
    }

    static mapTypeToTag(type){
        return (
            map[type] ?? type
        );
    }

    static buildElement({ field, idx, parent,  onInput=null, value=null, extra={}} = {}) {
        let atts = field.atts ?? {};
        return (
            <Poly atts={atts} onInput={onInput} extra={extra} parent={parent} value={value} as={this.mapTypeToTag(field.type)} key={idx} keyRef={idx} def={field}></Poly>
        )
    }    
}

export default EasElementHelper
import React, { Component } from 'react';
import AbstractFormBase from './AbstractFormBase';
import EasElementHelper from './EasElementHelper';
class EasFormFieldDate extends AbstractFormBase {

    constructor(props) {
        super(props);
   
    }

    get classbase() {
        return 'form-control';
    }

    onInput(e){
        let val = e.target.value;
        this.value = val;
    }
    get type(){
        return "date";
    }

    get inputAtts() {
        let defaultVals = {
            "min" : "2009-12-03",
            "max" : "2100-12-31"
        }
        let inputAtts = Object.assign(defaultVals, this.props.def.input_atts ?? {});
        inputAtts.required = this.hasFlag('required');
        return inputAtts;
    }

    render() {
        this.ref = React.createRef();
        return (
            <div className={this.state.className}>
                <label 
                    className={this.getClass('label')} 
                    htmlFor={this.uuid}
                >
                    {this.paramString('label')}                
                </label>

                <input 
                    ref={this.ref}
                    type={this.type}
                    className={this.getClass('input')} 
                    id={this.uuid} 
                    onInput={this.onInput.bind(this)}
                    onChange={this.onInput.bind(this)}
                    defaultValue={this.state.value}
                    {...this.inputAtts}
                ></input>

                {this.props.def.instructions ? (
                    <div 
                        className={this.getClass('instructions')}
                        dangerouslySetInnerHTML={{__html:this.paramString('instructions')}}
                    ></div>
                ) : null}

            </div>
        )
    }

}
EasElementHelper.addType('date', EasFormFieldDate);
export default EasFormFieldDate;
import React from 'react';
import AbstractComponent, { IStandardProps } from "../Shared/AbstractComponent";
import ComposedContent from "../Shared/ComposedContent";
import { ChevronRightFilled } from '@fluentui/react-icons';
import ReactDOMServer from 'react-dom/server';

import experts from "./expert-list.json";

export type IEasHero = IStandardProps & {
    onRegister: Function
}

class InsightBlock extends AbstractComponent<IEasHero> {

    constructor(props: IEasHero) {
        super(props);
    }

    get classbase() {
        return 'insight-block';
    }

    openDinnerForm() {
        this.props.onRegister();
    }

    openInsightForm() {
        this.props.onRegister();
    }

    get_room_details_html(date: string, time:string, location:string) {
        return ReactDOMServer.renderToString(
            <div className='room-details'>
                <div className='room-details__item'>
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21 8.5v9.25A3.25 3.25 0 0 1 17.75 21H6.25A3.25 3.25 0 0 1 3 17.75V8.5h18ZM7.25 15a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM12 15a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-4.75-4.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm4.75 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm4.75 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm1-7.5A3.25 3.25 0 0 1 21 6.25V7H3v-.75A3.25 3.25 0 0 1 6.25 3h11.5Z" fill="#212121"/></svg>
                    {date}
                </div>
                <div className='room-details__item'>
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.25 13.5h-4a.75.75 0 0 1-.75-.75v-6a.75.75 0 0 1 1.5 0V12h3.25a.75.75 0 0 1 0 1.5ZM12 2C6.478 2 2 6.478 2 12s4.478 10 10 10 10-4.478 10-10S17.522 2 12 2Z" fill="#212121"/></svg>
                    {time}
                </div>
                <div className='room-details__item'>
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m18.157 16.882-1.187 1.174c-.875.858-2.01 1.962-3.406 3.312a2.25 2.25 0 0 1-3.128 0l-3.491-3.396c-.439-.431-.806-.794-1.102-1.09a8.707 8.707 0 1 1 12.314 0ZM14.5 11a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Z" fill="#212121"/></svg>
                    {location}
                </div>
            </div>
        );
    }

    get_presenters_html(presenterNames: string[]) {
        const presenterElements = presenterNames.map((name) => {
            const expert = experts.find((exp) => exp.text.header === name);
    
            if (!expert) {
                return <div key={name}>{name}</div>;
            }
    
            return (
                <div key={name} className="presenter">
                    <div className="presenter__image">
                        <img 
                            src={expert.media.src} 
                            alt={expert.media.alt || name} 
                        />
                    </div>

                    <div className="presenter__content">
                        <div className="presenter__header">{expert.text.header}</div>
                        <div className="presenter__title">{expert.text.body}</div>
                    </div>
                </div>
            );
        });
    
        return ReactDOMServer.renderToString(<>{presenterElements}</>);
    }
    

    render() {
        return (
            <section className={this.state.className}>
                <div className={this.getClass("inner")}>
                    <div className={this.getClass("content")}>

                        <ComposedContent
                            classModifiers={{render:[ "mediatext" ]}}
                            identifier="insight-session"
                            media={{
                                type: 'image',
                                src: '/assets/implmenting-ai.jpg',
                                alt: 'logo'
                            }}
                            text={{
                                eyebrow: "Breakout session",
                                header: "Implementing AI transformation: the essentials of balancing efficiency and sustainable growth",
                                body: {
                                    text: [
                                        `In today’s fast-evolving landscape, AI transformation is crucial for businesses to remain competitive. However, achieving this at scale requires balancing financial efficiency with sustainable growth. In this session, industry leaders will explore how to drive AI innovation while optimizing costs and aligning with sustainability objectives. We’ll discuss actionable FinOps practices and tools to maximize the value of cloud investments without compromising environmental or financial integrity. Join us to learn how to lead an AI transformation that is both financially efficient and sustainable for the future.`,
                                        this.get_room_details_html("November 13, 2024", "12:00 PM", "Great Room"),
                                        "<strong>Speakers</strong>:",
                                        this.get_presenters_html(["Evelyn Padrino"]),
                                        '<div class="presenter"><div class="presenter__image"><img src="/assets/experts/sonal.jpeg" alt="Sonal Gupta"></div><div class="presenter__content"><div class="presenter__header">Sonal Gupta</div><div class="presenter__title">Senior Manager WE- FinOps & Cloud Hosting Services at Carlsberg Group</div></div></div>',
                                        this.get_presenters_html(["Francesco Sodano"]),
                                        this.get_presenters_html(["Ritesh Kini"]),

                                    ]
                                }
                            }}
                        />
                    </div>
                </div>
            </section>
        )
    }
}

export default InsightBlock;

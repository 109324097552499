import KewpiePath from "../KewpieCore/KewpiePath.js";
import EnumMap from "../util/EnumMap.js";
import {isScalar,isNumeric} from "../util/ObjectUtils.js";

import KewpieConditionDefinitionInvalidException from "./KewpieConditionDefinitionInvalidException.js";
import KewpieConditionInvalidException from "./KewpieConditionInvalidException.js";

class KewpieCondition {
    #operator;
    #path;
    #value;
    #parent;
    #current = undefined;

    constructor(path, operator, value, parent) {
        this.#path = KewpiePath.coalesce(path);
        let op = KewpieCondition.OPERATORS.tryFrom(operator);
        if (!op) {
            throw new Error(`Invalid operator ${operator}`);
        }
        this.#operator = op;
        this.#value = value;
        this.#parent = parent;
        this.__validate();
        this.__hook();
    } 


    serialize(){
        return {
            'path' : this.#path.serialize(),
            'operator' : this.#operator,
            'value' : this.#value
        }
    }

    get current(){

        if (this.#current === undefined) {
            this.#current = this.__check(
                this.__qp.get(this.#path)
            );
        }
        return this.#current;
    }


    get __qp(){
        return this.#parent.qp;
    }


    __hook(){
        this.__qp.observe(this.#path, function(evt) {
            let newVal = this.__check(evt.value);
            if (newVal !== this.#current) {
                this.#current = newVal;
                this.#parent.check();
            }
        }.bind(this));
    }



    __check(value) {
        let op = KewpieCondition.OPERATORS[this.#operator];
        switch (op) {
            case KewpieCondition.OPERATORS.EQUAL:
                return value == this.#value;
            case KewpieCondition.OPERATORS.NOT_EQUAL:
                return value != this.#value;
            case KewpieCondition.OPERATORS.GREATER_THAN:
                return value > this.#value;
            case KewpieCondition.OPERATORS.LESS_THAN:
                return value < this.#value;
            case KewpieCondition.OPERATORS.GREATER_THAN_OR_EQUAL:
                return value >= this.#value;
            case KewpieCondition.OPERATORS.LESS_THAN_OR_EQUAL:
                return value <= this.#value;
            case KewpieCondition.OPERATORS.CONTAINS:
                if ((value == null) || (value == undefined)) {
                    return false;
                }
                if (Array.isArray(value)) {
                    return value.includes(this.#value);
                } else if (typeof value === 'object') {
                    return Object.values(value).includes(this.#value);
                } else if (typeof value === 'string') {
                    return value.includes(this.#value);
                }
                break;

            case KewpieCondition.OPERATORS.NOT_CONTAINS:
                if ((value == null) || (value == undefined)) {
                    return false;
                }
                if (Array.isArray(value)) {
                    return !value.includes(this.#value);
                } else if (typeof value === 'object') {
                    return !Object.values(value).includes(this.#value);
                } else if (typeof value === 'string') {
                    return !value.includes(this.#value);
                }
                break;
            case KewpieCondition.OPERATORS.STARTS_WITH:
                return value.startsWith(this.#value);
            case KewpieCondition.OPERATORS.FALSY:
                return !value;
            case KewpieCondition.OPERATORS.TRUTHY:  
                return !!value;
        }

    }

    __validate(){
        let op = KewpieCondition.OPERATORS[this.#operator];
        switch (op) {
            case KewpieCondition.OPERATORS.EQUAL:
            case KewpieCondition.OPERATORS.NOT_EQUAL:
            case KewpieCondition.OPERATORS.CONTAINS:
            case KewpieCondition.OPERATORS.NOT_CONTAINS:
            case KewpieCondition.OPERATORS.STARTS_WITH:
                
                if (!isScalar(this.#value)) {
                    throw new KewpieConditionInvalidException(`Invalid value for operator ${this.#operator}`);
                }              
                break;
            case KewpieCondition.OPERATORS.GREATER_THAN:
            case KewpieCondition.OPERATORS.LESS_THAN:
            case KewpieCondition.OPERATORS.GREATER_THAN_OR_EQUAL:
            case KewpieCondition.OPERATORS.LESS_THAN_OR_EQUAL:
                if (!isScalar(this.#value)) {
                    throw new KewpieConditionInvalidException(`Invalid value for operator ${this.#operator}`);
                }              
                if (!isNumeric(this.#value)) {
                    throw new KewpieConditionInvalidException(`Invalid value for operator ${this.#operator}`);
                }
                break;
            case KewpieCondition.OPERATORS.FALSY:
            case KewpieCondition.OPERATORS.TRUTHY:  
                // falsy/truthy do not need a value.
                break;

            default:
                throw new KewpieConditionInvalidException(`Invalidoperator type ${this.#operator}`);
        }        
    }


}

KewpieCondition.OPERATORS = new EnumMap('string', {
    EQUAL : "=",
    NOT_EQUAL : "!=",
    GREATER_THAN : ">",
    LESS_THAN : "<",
    GREATER_THAN_OR_EQUAL : ">=",
    LESS_THAN_OR_EQUAL : "<=",
    CONTAINS : "~=",
    NOT_CONTAINS : "!~",
    FALSY : "!",
    TRUTHY : "!!",
    STARTS_WITH : "^="
});

export default KewpieCondition;
import React, { MouseEventHandler } from 'react';
import AbstractComponent, { IStandardProps } from "../Shared/AbstractComponent";
import ComposedContent from "../Shared/ComposedContent";
import { ChevronRightFilled,  } from '@fluentui/react-icons';
import ReactDOMServer from 'react-dom/server';
import experts from "./expert-list.json";

export type IEasHero = IStandardProps & {
    onRegister: Function
}

class EventsBlock extends AbstractComponent<IEasHero> {

    constructor(props: IEasHero) {
        super(props);
    }

    get classbase() {
        return 'sessions-block';
    }

    get_room_details_html(date: string, time:string, location:string) {
        return ReactDOMServer.renderToString(
            <div className='room-details'>
                <div className='room-details__item'>
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21 8.5v9.25A3.25 3.25 0 0 1 17.75 21H6.25A3.25 3.25 0 0 1 3 17.75V8.5h18ZM7.25 15a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM12 15a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-4.75-4.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm4.75 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm4.75 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm1-7.5A3.25 3.25 0 0 1 21 6.25V7H3v-.75A3.25 3.25 0 0 1 6.25 3h11.5Z" fill="#212121"/></svg>
                    {date}
                </div>
                <div className='room-details__item'>
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.25 13.5h-4a.75.75 0 0 1-.75-.75v-6a.75.75 0 0 1 1.5 0V12h3.25a.75.75 0 0 1 0 1.5ZM12 2C6.478 2 2 6.478 2 12s4.478 10 10 10 10-4.478 10-10S17.522 2 12 2Z" fill="#212121"/></svg>
                    {time}
                </div>
                <div className='room-details__item'>
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m18.157 16.882-1.187 1.174c-.875.858-2.01 1.962-3.406 3.312a2.25 2.25 0 0 1-3.128 0l-3.491-3.396c-.439-.431-.806-.794-1.102-1.09a8.707 8.707 0 1 1 12.314 0ZM14.5 11a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Z" fill="#212121"/></svg>
                    {location}
                </div>
            </div>
        );
    }

    get_presenters_html(presenterNames: string[]) {
        const presenterElements = presenterNames.map((name) => {
            const expert = experts.find((exp) => exp.text.header === name);
    
            if (!expert) {
                return <div key={name}>{name}</div>;
            }
    
            return (
                <div key={name} className="presenter">
                    <div className="presenter__image">
                        <img 
                            src={expert.media.src} 
                            alt={expert.media.alt || name} 
                            // width="50" 
                            // height="50" 
                        />
                    </div>

                    <div className="presenter__content">
                        <div className="presenter__header">{expert.text.header}</div>
                        <div className="presenter__title">{expert.text.body}</div>
                    </div>
                </div>
            );
        });
    
        return ReactDOMServer.renderToString(<>{presenterElements}</>);
    }

    render() {
        return (
            <section className={this.state.className}>
                <div className={this.getClass("inner")}>
                    <div className={this.getClass("content")}>
                    <ComposedContent
                            className={this.getClass("header")}
                            text={{
                                header: 'Ask the expert sessions'
                            }}
                        />
                        <div className={this.getClass('blocks')}>
                            <ComposedContent
                                className={this.getClass("chiclet")}
                                identifier="chevron"
                                text={{
                                    eyebrow: "Ask the expert",
                                    header: "Microsoft products and solutions for FinOps",
                                    subheader: "Join an interactive discussion with Microsoft experts about the latest FinOps products and solutions from Microsoft. You’ll learn about Microsoft products and solutions, like Microsoft Cost Management, Azure Advisor, Copilot for Azure, and the FinOps toolkit. Bring your questions and join us for an interactive conversation about Microsoft’s FinOps tools.",
                                    body: {
                                        text: [
                                            this.get_room_details_html("November 12, 2024", "11:00 AM", "Room Breeze 1"),
                                            '<strong>Speakers:</strong>',
                                            this.get_presenters_html(["Michael Flanakin"]),
                                            this.get_presenters_html(["Ritesh Kini"]),
                                            this.get_presenters_html(["Arthur Clares"]),
                                        ]
                                    },
                                    disclaimer: `<span style="color:red">Full Capacity</span>`,
                                    actions: [ ],
                                }}
                            />
                            <ComposedContent
                                className={this.getClass("chiclet")}
                                identifier="trends"
                                text={{
                                    eyebrow: "Ask the expert",
                                    header : "Building an AI-powered Fabric workspace for FinOps",
                                    subheader: "Join an interactive discussion with Microsoft experts on how to leverage Microsoft Fabric and Microsoft Copilot to transform your FinOps practice. You’ll learn about Fabric and Copilot capabilities and how they can empower stakeholders across the organization to achieve more with an AI-powered, self-serve analytics platform. Bring your questions and join us for an interactive conversation about the future of FinOps, powered by Microsoft Fabric.",
                                    body: {
                                        text: [
                                            this.get_room_details_html("November 13, 2024", "11:00 AM", "Room Breeze 1"),
                                            '<strong>Speakers:</strong>',
                                            this.get_presenters_html(["Michael Flanakin"]),
                                            this.get_presenters_html(["Thierry Houy"]),
                                        ]
                                    },
                                    disclaimer: `<span style="color:red">Full Capacity</span>`,
                                    actions: [ ],
                                }}
                            />
                            <ComposedContent
                                className={this.getClass("chiclet")}
                                identifier="ai-era"
                                text={{
                                    eyebrow: "Ask the expert",
                                    header: "Elevate your cloud sustainability approach on Azure",
                                    subheader: "Join an interactive discussion with Microsoft experts on how to leverage Azure Carbon Optimization to measure and optimize the carbon footprint of your Azure environment. Bring your questions and join us for an interactive conversation about sustainability.",
                                    body: {
                                        text: [
                                            this.get_room_details_html("November 13, 2024", "2:00 PM", "Room Breeze 1"),
                                            '<strmng>Speakers:</strong>',
                                            this.get_presenters_html(["Ritesh Kini"]),
                                        ]
                                    },
                                    disclaimer: `<span style="color:red">Full Capacity</span>`,
                                    actions: [ ],
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default EventsBlock;

import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import EasStrings from './EasStrings.js';
import GlobalStringMap from "./GlobalStringMap.jsx";

class AbstractEasComponent extends Component {
    #modifiers = {};

    constructor(props) {
        super(props);

        this.state = {
            className : this.getRootClass()
        };

        if (props.ident) {
            this.setModifier('id', props.ident, false);
        }
        if (props.modifiers) {
            for (var key in props.modifiers) {
                this.setModifier(key, props.modifiers[key], false);
            }
        }
    }

    // static get StringMap(){
    //     return new EasStrings({});
    // }

    // get strings(){
    //     if (!this.__strings) {
    //         this.__strings = this.constructor.StringMap;
    //     }
    //     return this.__strings;
    // }

    // set strings(val) {
    //     this.__strings = val;
    // }

    get flags(){
        return [];
    }

    get classbase(){
        throw new Error("classbase must be defined");
    }
    get type(){
        return null;
    }

    get def(){
        return this.props.def;
    }
    param(key){
        return (this.props.def && this.props.def[key]) ?? this.props[key] ?? null;
    }

    paramString(key){
        let param = this.param(key);
        return param;
        if (param) {
            return GlobalStringMap.get(param) ?? param;
        }
        return null;
    }

    get modifiers(){
        return this.#modifiers;
    }

    setModifier(key, val, updateState=true) {
        this.#modifiers[key] = val;

        if (updateState) {  
            this.setState({className: this.getRootClass()});
        } else {
            this.state.className = this.getRootClass();
        }
    }
    unsetModifier(key, updateState = true){
        delete this.#modifiers[key];
        if (updateState) {  
            this.setState({className: this.getRootClass()});
        }
    }

    getRootClass(){
        let fields = [
            this.classbase
        ];
        if (this.type) {
            fields.push(`${this.classbase}--type--${this.type}`);
        }
        Object.keys(this.modifiers).forEach(modifier => {
            let modItems= this.modifiers[modifier];
            if (!Array.isArray(modItems)) {
                modItems = [modItems];
            }

            for (var ii=0; ii < modItems.length; ii++) {
                let modlist = modItems[ii];
                if (!Array.isArray(modlist)) {
                    modlist = [modlist];
                }
                for (var jj=0; jj<modlist.length; jj++) {
                    fields.push(`${this.classbase}--${modifier}--${modlist[jj]}`);
                }
            }

        });
        if (this.props.extraClasses) {
            if (!Array.isArray(this.props.extraClasses)) {
                fields.concat(this.props.extraClasses);
            } else {
                fields.push(this.props.extraClasses);
            }
        }
        return fields.join(" ");
    }
    

    getClass(...subclasses) {
        let accum = [];
        let modifiers = {};

        for (var ii=0; ii < subclasses.length; ii++) {
            if (Array.isArray(subclasses[ii])) {
                let subItems = {...subclasses[ii]};
                accum.push(...subclasses[ii]);
            } else if (typeof subclasses[ii] === 'string') {
                accum.push(subclasses[ii]);
            } else {
                modifiers = Object.assign(modifiers, subclasses[ii]);
            }
        }
        accum = accum.join('__');
        if (accum) {
            accum = `__${accum}`;
        }
        let ret = `${this.classbase}${accum}`;

        if (Object.keys(modifiers).length == 0) {
            return ret;
        }

        let extra = [];
        Object.keys(modifiers).forEach(modifier => {
            extra.push(`${ret}--${modifier}--${modifiers[modifier]}`);
        });

        this.flags.forEach(flag => {
            extra.push(`${ret}--${flag}`);
        });



        return `${ret} ${extra.join(' ')}`;;
    }

    async delay(ms) {
        return new Promise(function(resolve, reject){
            setTimeout(function(){
                resolve();
            }, ms)
        });
    }

}

export default AbstractEasComponent;

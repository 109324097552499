import React from 'react';
import AbstractComponent, { IStandardProps } from "../Shared/AbstractComponent";
import AdminTable from "./Components/Table"
import AdminStats from "./Components/AdminStats"
import SessionMap, { ISessionMap } from './Helpers/SessionMap';

export interface IEasHero extends IEasHeroProxy {
    tables: any,
    session_map: ISessionMap|null,
    stats: any,
    updateStats: () => void
};

export interface IEasHeroProxy extends IStandardProps { }; 

class AdminApp extends AbstractComponent<IEasHero> {

    get classbase(): string {
        return 'admin-main';
    }

    getSessionTitle(key:string) : string {
        const session_map = this.props.session_map
        if(session_map) {
            const session_map_obj = session_map[key];
            if(session_map_obj) {
                return session_map_obj.title;
            }
        }
        return "";
    }

    render() {
        return (
            <div className={this.classbase}>
                <div className={this.getClass('inner')}>
                    <div className={this.getClass('content')}>
                        <div className={this.getClass('header')}>
                            <h1>Admin</h1>
                            <a href="/api/downloadCSV">Download registrations</a>
                        </div>
                        <AdminStats stats={this.props.stats} session_map={this.props.session_map ?? {} as ISessionMap} />
                        <div className={this.getClass("waitlist-tables")}>
                            { this.props.tables.map(( table:any ) => {
                                return (
                                    <div key={table.session_key} className={this.getClass("waitlist-section")}>
                                        <h2>{this.getSessionTitle(table.session_key)}</h2>
                                        <AdminTable update_stats={this.props.updateStats} key={table.session_key} table_data={table.table_data} activity={table.session_key} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

const Proxy = ( props : IEasHeroProxy ) =>  {
    const [sessionMap, setSessionMap] = React.useState<ISessionMap|null>(null)
    const [tables, setTables] = React.useState<any>([])
    const [stats, setStats] = React.useState<any>(null)
    const [nonce] = React.useState<number>(0)

    const updateStats = () => {
        fetch("/api/getStats").then( res => {
            return res.json()
        }).then( stats => {
            setStats(stats);
        })
    }

    React.useEffect( () => {
        SessionMap.getSessionMap().then(session_map => {
            setSessionMap(session_map)
        })
    }, [nonce])

    React.useEffect( () => {
        if(sessionMap == null) return;
        const sessions = Object.keys(sessionMap);

        const promises = [];

        for(let ii = 0; ii < sessions.length; ii ++) {

            const session = sessions[ii]
            const promise = fetch(`/api/getWaitlisted?sessions=${session}`).then( res => {
                return res.json()
            }).then( session_users => {
                return {
                    session_key: session,
                    table_data: session_users
                }
            })

            promises.push(promise)
        }

        Promise.all(promises).then(arr => {
            setTables(arr)
        })
 
        updateStats();

    }, [sessionMap])

    const updated = {...props, session_map: sessionMap, tables, stats, updateStats}
    return <AdminApp {...updated} /> 
}

export default Proxy;

import KewpieException from "../Errors/KewpieException.js";


class KewpieConditionInvalidException extends KewpieException {
    constructor(message){
        super(message);
        this.name = "KewpieConditionInvalidException";
    }
}

export default KewpieConditionInvalidException;
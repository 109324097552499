export interface ISessionMap {
    [key:string]: ISessionMapObj
}

export interface ISessionMapObj {
    title: string,
    state_title: string
}

export default class SessionMap {

    private static map_key = "session_map"
    private static current_version = "barcelona-2024-v1";

    static async getSessionMap() : Promise<ISessionMap> {
        let session_map = localStorage.getItem(this.map_key);
        if(session_map) {
            session_map = JSON.parse(session_map);
            if(session_map.version != this.current_version) {
                session_map = null;
            } else {
                session_map = session_map.data
            }
        }
        if(session_map) {
            this.apiGet();
            return new Promise( (resolve) => {
                resolve(session_map);
            });
        } else {
            return await this.apiGet();
        } 
    }

    private static async apiGet() : Promise<ISessionMap> {
        const res = await fetch(`/api/getSessionMap?`, {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        })
        const json = await res.json()
        const storage_item = {
            data: json,
            version: this.current_version
        }
        localStorage.setItem(this.map_key, JSON.stringify(storage_item))
        return json as ISessionMap;
    }
}

import React, { Component } from 'react';
import AbstractFormBase from './AbstractFormBase';
import EasElementHelper from './EasElementHelper';
class EasFormFieldButton extends AbstractFormBase {

    constructor(props) {
        super(props);
    }

    get classbase() {
        return 'form-button';
    }
    get value(){}
    set value(value){}

    onClick(e){
        if (this.props.onClick) {
            let evt = new CustomEvent('click',{
                detail: {
                    ref: this.ref,
                    identifier: this.identifier,
                    action: this.props.action
                }
            });
            this.props.onClick(evt, e);
        }

        // let val = e.target.checked;
        // this.value = val;
    }

    render() {

        this.ref = React.createRef();
        return (
            <button 
                type="button"
                ref={this.ref} 
                action={this.props.action}
                disabled={this.props.disabled}
                className={this.state.className} 
                onClick={this.onClick.bind(this)}>
                {this.paramString('text') ?? this.props.text}
            </button>
        )
    }

}
EasElementHelper.addType('button', EasFormFieldButton);
export default EasFormFieldButton;

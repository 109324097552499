import {isString} from '../util/StringUtils.js';
import EnumMap from '../util/EnumMap.js';
import KewpiePathInvalidException from '../Errors/KewpiePathInvalidException.js';
import KewpiePathInvalidPartException from '../Errors/KewpiePathInvalidPartException.js';
import KewpiePathInvalidTypeException from '../Errors/KewpiePathInvalidTypeException.js';

class KewpiePath {
    #path;
    #pathType;
    #pathCore;
    #pathPredicate;
    #pathDepth;

    #pathTypeFriendly;
    #predicates;
    

    constructor(pathspec) {
        this.#path = pathspec;
        this.__parse();

        if (this.is(KewpiePath.PATH.PATH_INVALID)) {
            throw new KewpiePathInvalidException(`Invalid path: ${pathspec}`);
        }

    }
 
    __parse(){
        let $ref = this.constructor.parse_path_real(this.#path);
        this.#path = $ref.full ?? null;
        this.#pathCore = $ref.core ?? null;
        this.#pathPredicate = $ref.predicates ?? null;
        this.#pathDepth = $ref.depth ?? null;
        this.#pathType = $ref.type;
        this.#pathTypeFriendly = $ref.type_friendly;
        this.#predicates = $ref.predicates ?? [];
    }

    serialize(){
        return {
            path: this.pathRaw,
            pathCore: this.pathCore,
            type: this.type,
            depth: this.depth,
            predicates: this.predicates,
            arr: this.to_kparr()
        }
    }

    endsWith(part) {
        let parts = this.to_kparr();
        let last = parts.pop();
        if (last == part) {
            return true;
        }
        return false;
    }


    static getPrimaryRegex(){
        return ['^',KewpiePath.REGEX.CORE, KewpiePath.REGEX.PREDICATE,'$'].join('');
    }
    static getPredicateRegex(){
        return [KewpiePath.REGEX.PREDICATE_DIRECT_OPERATOR,KewpiePath.REGEX.PREDICATE_MODIFIER,  KewpiePath.REGEX.PREDICATE_KEYED_OPERATOR].join('');        
    }



    static coalesce(path){
        if (path instanceof KewpiePath) {
            return path;
        }
        return new this(path);
    }
    static fromParts(...$parts) {
        return this.fromArray($parts)
    }
    static fromArray(arr) {
        let core = [], preds = [], depth = [];

        for (var ii=0; ii < arr.length; ii++) {
            if (arr[ii] == '') continue;
            let fixed = this.encodePathPart(arr[ii]);
            if (isString(fixed) && fixed.indexOf('(') > -1) {
                preds.push(fixed);
            } else if (isString(fixed) && fixed.indexOf('{') > -1) {
                depth.push(fixed);
            } else {
                core.push(fixed);
            }
        }
        let finalPath = core.join('.')+preds.join('')+depth.join('');
        return new this(finalPath);
    }
    static encodePathPart(part) {
        if (Number.isInteger(part)) {
            return part;
        }
        if (Number.isFinite(part)) {
            throw new KewpiePathInvalidPartException(`Cannot encode path part: ${part}`);
        }
        if (isString(part)) {
            part = part.replace('.', KewpiePath.DOT_ALTERNATE);               
            return part;
        }
        throw new KewpiePathInvalidPartException(`Cannot encode path part: ${part}`);
    }

    static decodePathPart(part) {
        if (isString(part)) {
            part = part.replace(KewpiePath.DOT_ALTERNATE, '.');
        }
        return part;
    }
   
    toString(){
        return this.pathCore;
    }

    get pathRaw() {
        return this.#path;
    }

    get path(){
        return this.#pathCore;
    }

    get pathCore(){
        return this.#pathCore
    }
    get pathPredicate(){
        return this.#pathPredicate;
    }
    get type(){
        return this.#pathTypeFriendly;
    }
    get depth(){
        return this.#pathDepth;
    }
    get predicates(){
        return this.#predicates;
    }
    get directPredicates(){
        return this.#predicates.directMatches ?? [];
    }
    get keyedPredicates(){
        return this.#predicates.keyedMatches ?? [];
    }
    get modifierPredicates(){
        return this.#predicates.modifiers ?? [];
    } 

    to_kparr(){
        return this.constructor.p2kp(this.pathCore);
    }

    match(path) {
        path = this.constructor.coalesce(path);
        let self = this.to_kparr();
        let other = path.to_kparr();
        let len = Math.min(self.length, other.length);

        for (var ii=0; ii < len; ii++) {

            if ((self[ii] === '?') || (other[ii] === '?')) {
                continue;
            }

            if (isString(self[ii]) && self[ii].indexOf('?') > -1) { continue; }
            if (isString(other[ii]) && other[ii].indexOf('?') > -1) { continue; }

            if (self[ii] !== other[ii]) {
                return false;
            }
        }
        return true;
    }

    is(bit) {
        return (this.#pathType & bit) == bit;
    }
    not(bit) {
        return (this.#pathType & bit) != bit;
    }
    get isNull() {
        return this.is(KewpiePath.PATH.PATH_NULL);
    }



    get isSimple() {
        let $sok = true;
        $sok = $sok && this.not(KewpiePath.PATH.PATH_HAS_PREDICATE);
        $sok = $sok && this.not(KewpiePath.PATH.PATH_HAS_DEPTH);
        $sok = $sok && this.not(KewpiePath.PATH.PATH_WILDCARD);
        return $sok;
    }


    simplify() {
        return new this.constructor(this.pathCore);
    }
    static getTypes(path){
        let re = new RegExp(KewpiePath.REGEX.FULL);
        let res = re.exec(path);

        if (res) {
            return res.groups;
        }

        return null;
    }

    static p2kp(path) {
        if (!path) {
            return [];
        }
        var out = [];
        if (isString(path) && path.indexOf('[') !==false) {
            var tmp = '';
            var length = path.length;   

            for (var ii=0; ii < length; ii++) {
                var char = path[ii];
                if (char === '.') {
                    if (tmp.length) {
                        out.push(tmp);
                    }
                    tmp = '';
                }
                else if ((char === '[') || (char === ']')) {
                    out.push(tmp);
                    tmp = '';
                }
                else {
                    tmp += char;
                }
            }
            if (tmp.length) {
                out.push(tmp);
            }
        } else {
            out = isString(path) ? path.split('.') : path;
        }

        out = out.map((item) => {
            item = this.decodePathPart(item);
            if (item.match(/^\d+$/)) {
                return parseInt(item);
            }
            return item;
        });
        return out;
    }

    static parse_predicates(strPredicates) {
        let ret = {
            directMatches: [],
            keyedMatches: [],
            modifiers: {}
        };
        if (!strPredicates) {
            return ret;
        }
        let re = new RegExp(this.getPredicateRegex(), 'gm');
        let m;
        let kMods = {};

        while ((m = re.exec(strPredicates)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === re.lastIndex) {
                re.lastIndex++;
            }
            let curr = m.groups;

            if (curr.dvalue) {
                // There can be only 1.
                if (!ret.directMatches.length) {
                    ret.directMatches.push({
                        operator: curr.doperator ?? '=',
                        value: curr.dvalue
                    });    
                }
            }
            if (curr.modifier) {
                ret.modifiers[curr.modifier] = curr.modifiervalue ?? null;
            }
            if (curr.koperator) {
                let k = curr.k;
                let tPath = `${curr.k}${curr.koperator}${curr.kvalue}`;
                kMods[tPath] = {
                    operator: curr.koperator,
                    value: curr.kvalue
                }
            }
        }
        ret.keyedMatches = Object.values(kMods);
        return ret;

    }

    static reverseEnum(value, items){
        let accum = [];
        for (var item in items) {
            if (items[item] & value) {
                accum.push(item);
            }
        }
        return accum;
    }

    getDirectPredicate(){
        return this.directPredicates[0] ?? null;
    }

    static testPredicate(predicate, target) {
        let value = predicate.value;
        let op = predicate.operator ?? '=';
        if (op == '') op = '=';

        KewpiePath.PREDICATE_OPERATORS.tryFrom(op);

        switch (op) {
            case KewpiePath.PREDICATE_OPERATORS.EQUAL:
                return value == target;
            case KewpiePath.PREDICATE_OPERATORS.NOT_EQUAL:
                return value != target;
            case KewpiePath.PREDICATE_OPERATORS.GREATER_THAN:
                return value > target;
            case KewpiePath.PREDICATE_OPERATORS.LESS_THAN:
                return value < target;
            case KewpiePath.PREDICATE_OPERATORS.GREATER_THAN_OR_EQUAL:
                return value >= target;
            case KewpiePath.PREDICATE_OPERATORS.LESS_THAN_OR_EQUAL:
                return value <= target;
            case KewpiePath.PREDICATE_OPERATORS.CONTAINS:
                if ((value === null) || (value === undefined)) {
                    return false;
                }
                if (Array.isArray(value)) {
                    return value.contains(target);
                } else {
                    return value.includes(target);
                }
            case KewpiePath.PREDICATE_OPERATORS.NOT_CONTAINS:
                if ((value === null) || (value === undefined)) {
                    return false;
                }
                if (Array.isArray(value)) {
                    return !value.contains(target);
                } else {
                    return !value.includes(target);
                }
            case KewpiePath.PREDICATE_OPERATORS.FALSY:
                return !(value);
            case KewpiePath.PREDICATE_OPERATORS.TRUTHY:
                return !!(value);
            case KewpiePath.PREDICATE_OPERATORS.STARTS_WITH:
                return value.indexOf(target) === 0;
            default:
                throw `Invalid operator {$operator}`;
        }
        return true;

    }



    static parse_path_real(strPath){
        let ret = {};
        if (!strPath) {
            ret.type = KewpiePath.PATH.PATH_NULL;
            return ret;
        }
        if (!((typeof strPath === 'string' || strPath instanceof String))) {
            throw new KewpiePathInvalidTypeException("Path must be string or null.");
        }

        strPath = strPath.trim();

        let reFull = this.getPrimaryRegex();
        // `${[KewpiePath.REGEX.CORE, KewpiePath.REGEX.PREDICATE, KewpiePath.REGEX.DEPTH].join('')}$`;
        let re = new RegExp(reFull, 'mi');
        let res = re.exec(strPath);

        if (!res) {
            ret.type = KewpiePath.PATH.PATH_INVALID;
            return ret;
        }
        ret.full = strPath;
        ret.type = KewpiePath.PATH.PATH_VALID;
        ret.predicates = this.parse_predicates(res.groups.predicates);


        let mods = ret.predicates.modifiers;
        if (mods.depth ?? false) {
            ret.depth = parseInt(mods.depth,10);
            ret.type |= KewpiePath.PATH.PATH_HAS_DEPTH;
            delete mods.depth;
        }
        // ret.predicates.modifiers = Object.keys(mods);
        ret.predicates.modifiers = Object.keys(ret.predicates.modifiers);



        if (strPath.indexOf('?') > -1) {
            ret.type |= KewpiePath.PATH.PATH_WILDCARD;
        }
        if (res.groups.core) {
            ret.core = res.groups.core;
            ret.core = ret.core.trim();
        }
        if (res.groups.predicates) {
            // ret.predicates = res.groups.predicates;
            ret.type |= KewpiePath.PATH.PATH_HAS_PREDICATE;
        }
        if (res.groups.depth) {
            ret.depth = parseInt(res.groups.depth);
            ret.type |= KewpiePath.PATH.PATH_HAS_DEPTH;
        }
        ret.type_friendly = KewpiePath.reverseEnum(ret.type, KewpiePath.PATH);
        return ret;
    }
}
KewpiePath.PATH = {};
KewpiePath.PATH.PATH_VALID        = 0b000001;
KewpiePath.PATH.PATH_NULL          = 0b000010;
KewpiePath.PATH.PATH_WILDCARD      = 0b000100;
KewpiePath.PATH.PATH_HAS_PREDICATE = 0b001000;
KewpiePath.PATH.PATH_HAS_DEPTH     = 0b010000;
KewpiePath.PATH.PATH_INVALID       = 0b100000;

// KewpiePath.REGEX2 = {
//     SIMPLE : '^(?<core>[a-zA-Z_]{1}[a-zA-Z0-9_\\.\\-\\?\\:]*)',
//     PREDICATE : '(\\((?<predicate>[a-zA-Z0-9_\\.\\-\\?\\{\\}\\=?\\\'\\"\\$\\^\\!\\*\\:]*)\\)*)*',
//     DEPTH : '(\\{(?<depth>\\d)\\})?\\s?'
// }

KewpiePath.REGEX = {}

//     CORE:       '^(?<core>[a-zA-Z_]{1}[a-zA-Z0-9_\\․\\.\\-\\?\\:\\@\\~]*[a-zA-Z0-9_\\-\\?\\:\\@\\~]{1})',
//     PREDICATE:  '(?<predicates>(\\(([a-zA-Z0-9_\\․\\.\\-\\?\\{\\}\\=?\\\'\\"\\$\\^\\!\\*\\:]*)\\))*)',
//     DEPTH:      "(\\{(?<depth>\\d)\\})?",
//     PREDICATE_DIRECT_OPERATOR: '(\\((?<doperator>[!^*]*)=(?<dvalue>[\\w]*)\\))?(\\((?<k>[a-zA-Z_]{1}[a-zA-Z0-9_"\\\'\\-@:~\\?]*)',
//     PREDICATE_KEYED_OPERATOR: '(?<koperator>[!^*=]*)(?<kvalue>[a-zA-Z0-9_"\\\'\\-@:~\\?]*)\\))?',
//     PREDICATE_MODIFIER: '(\\(:(?<modifier>[\\w]*)\\))?'
// }


KewpiePath.REGEX.START_CHARS = 'a-zA-Z0-9_:\\/';
KewpiePath.REGEX.START_WITHOUT_COLON = 'a-zA-Z0-9_\\/';
KewpiePath.REGEX.PREDICATE_MODIFIER_CHARS = 'a-zA-Z0-9&';


KewpiePath.REGEX.END_CHARS = KewpiePath.REGEX.START_CHARS+'․\\?@~\\{\\}\\-';
KewpiePath.REGEX.ANY_LETTER = KewpiePath.REGEX.END_CHARS+'.';
KewpiePath.REGEX.ANY_LETTER_QUOTABLE = KewpiePath.REGEX.ANY_LETTER+'\\\'\\"';

KewpiePath.REGEX.ANY_LETTER_PREDICATE = KewpiePath.REGEX.ANY_LETTER+'.*=^!&><~';

KewpiePath.REGEX.CORE_SELECTOR = '['+KewpiePath.REGEX.START_CHARS+']{1}['+KewpiePath.REGEX.ANY_LETTER+']*(?<!\\.)';

KewpiePath.REGEX.CORE = '(?<core>'+KewpiePath.REGEX.CORE_SELECTOR+')';

KewpiePath.REGEX.OPERATORS = '[=^!><~]{1,2}';

KewpiePath.REGEX.PREDICATE = '(?<predicates>(\\((['+KewpiePath.REGEX.ANY_LETTER_PREDICATE+']*)\\))*)';

KewpiePath.REGEX.PREDICATE_DIRECT_OPERATOR = '(\\((?<doperator>'+KewpiePath.REGEX.OPERATORS+')(?<dvalue>['+KewpiePath.REGEX.ANY_LETTER+']*)\\))?';

KewpiePath.REGEX.PREDICATE_KEYED_OPERATOR = '(\\((?<k>(['+KewpiePath.REGEX.START_WITHOUT_COLON+']{1}['+KewpiePath.REGEX.ANY_LETTER+']*['+KewpiePath.REGEX+']{1})*)(?<koperator>'+KewpiePath.REGEX.OPERATORS+')(?<kvalue>['+KewpiePath.REGEX.ANY_LETTER_QUOTABLE+']*)\\))?';
KewpiePath.REGEX.PREDICATE_MODIFIER = '(\\(&(?<modifier>['+KewpiePath.REGEX.START_CHARS+']*)(=(?<modifiervalue>['+KewpiePath.REGEX.ANY_LETTER_QUOTABLE+']*))?\\))?';

KewpiePath.DOT_ALTERNATE = '․';

KewpiePath.PREDICATE_OPERATORS = new EnumMap('string', {
    EQUAL : "=",
    NOT_EQUAL : "!=",
    GREATER_THAN : ">",
    LESS_THAN : "<",
    GREATER_THAN_OR_EQUAL : ">=",
    LESS_THAN_OR_EQUAL : "<=",
    CONTAINS : "~=",
    NOT_CONTAINS : "!~",
    FALSY : "!",
    TRUTHY : "!!",
    STARTS_WITH : "^="    
});

/*
'(\\((?<doperator>[!^*]*)=(?<dvalue>[\\w]*)\\))?(\\((?<k>[a-zA-Z_]{1}[a-zA-Z0-9_"\\'\\-@:~\\?]*)'
'(?<koperator>[!^*=]*)(?<kvalue>[a-zA-Z0-9_"\\'\\-@:~\\?]*)\\))?'
'(\\(:(?<modifier>[\\w]*)\\))?'
*/

// const str = `foo.bar.1.asdf.adsff
// foo.?.bar.asdf
// fdas@.asdfsaf.asfd
// 1.12.awe3
// foo.bar.1.?.adsff(=foo){5}
// foo.bar.1.?.adsff(*=foo)(bar*=foo)(foo=bar)(:parent){5}`;
// str.split("\n").forEach((line) => {
//     KewpiePath.parse_path_real(line);
// //     console.log(line);
// //     KewpiePath.getTypes(line);
// //     console.log('---');
// });


export default KewpiePath;
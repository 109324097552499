
import KewpieException from "./KewpieException.js";

class KewpiePathInvalidException extends KewpieException {
    constructor(message){
        super(message);
        this.name = "KewpiePathInvalidException";
    }
}

export default KewpiePathInvalidException;
import KewpieData from './KewpieCore/KewpieData.js';

class Kewpie extends KewpieData {

    constructor(data){
        super(data);
        // this.#data = data;
    }
 
}

export default Kewpie;
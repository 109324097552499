
import KewpieException from "./KewpieException.js";

class KewpiePathInvalidPartException extends KewpieException {
    constructor(message){
        super(message);
        this.name = "KewpiePathInvalidPartException";
    }
}

export default KewpiePathInvalidPartException;
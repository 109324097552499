class KeyPathObserver {

    static get STATE_FIREABLE () { return 'fireable'; }  
    static get STATE_DISABLED () { return 'disabled'; }

    construct(keypather, keypath, closure, extra, once){
        this.keypather = keypather;
        this.keypath = keypath;
        this.closure = closure;
        this.extra = extra ? extra : null;
        this.state = KeyPathObserver.STATE_FIREABLE;
        this.once = once ? true : false;
        this.serial = ++KeyPathObserver.__serial;
    }

    destroy() {
        this.state = KeyPathObserver.STATE_DISABLED;
    }

    run(newVal, oldVal, keypath) {
        if (this.state !== KeyPathObserver.STATE_FIREABLE) { return; }
        if (newVal === oldVal) { return; }

        this.closure(newVal, oldVal, keypath, this.extra);
        if (this.once) {
            this.destroy();
        }
    }

}
KeyPathObserver.__serial = 0;

export default KeyPathObserver;

import KewpieException from "../Errors/KewpieException.js";

class KewpieConditionDefinitionInvalidException extends KewpieException {
    constructor(message){
        super(message);
        this.name = "KewpieConditionDefinitionInvalidException";
    }
}

export default KewpieConditionDefinitionInvalidException;
import React, { Component } from 'react';
import EasFormFieldText from './EasFormFieldText';
import EasElementHelper from './EasElementHelper';
class EasFormFieldPostal extends EasFormFieldText {
    __country = null;

    get pattern(){
        switch (this.country) {
            case 'CA':
                return '[A-Za-z][0-9][A-Za-z][\\s\\-]?[0-9][A-Za-z][0-9]';
            case 'US':
                return '[0-9]{5}(?:-[0-9]{4})?';
            default:
                return null;
        }
    }

    get country(){
        if (!this.__country) {
            this.__country = this.props.country ?? (this.props.def.atts && this.props.def.atts.country) ?? 'US';
        }
        return this.__country;
    }
    setCountry(code) {
        this.__country = code;
        this.setState({
            country : code,
            pattern: this.pattern
        });
    }
    onCountryChange(e) {
        this.setCountry(e.detail.value ?? null);
    }

    hook(){
        this.props.parent.events.listen('country:change', this.onCountryChange.bind(this));
    }

}
EasElementHelper.addType('postal', EasFormFieldPostal);
export default EasFormFieldPostal;
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ClassnameManager, {IClassnameProps} from '../Helpers/ClassnameManager';

export type IStandardProps = IClassnameProps & {
    type?:string,
    navigate?:any
}

type KeyValuePair = {
    [key:string]:string
}

class AbstractComponent<IStandardProps> extends Component<IStandardProps> {
    private __ref:any = null;
    private __cm:ClassnameManager|null = null;
    private __mounted = false;
    private __id:string|null = null;
    private __ids:KeyValuePair = {};

    state:any;

    constructor(props:IStandardProps) {
        super(props);

        this.init();
        
        this.state = {
            className : this.getRootClass()
        };
    }

    get ref(){
        return this.__ref;
    }

    init(){
        // override this
    }

    componentDidMount() {
        this.__mounted = true;
        this.__ref = React.createRef();
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
        // this.cm.checkProps(this.props);
        // console.log(this.cm.getRootClass());
        // this.cm.updateProps(this.props);    
    }

    setStateProxy(newState:any){
        if (this.__mounted) {
            this.setState(newState);
        } else {
            // eslint-disable-next-line
            this.state = Object.assign(this.state, newState);
        }
    }

    getId(key:string):string{
        if (!this.__ids[key]) {
            this.__ids[key] = `id_${uuidv4()}`;
        }
        return this.__ids[key];
    }

    get id(){
        if (!this.__id) {
            this.__id = `id_${uuidv4()}`;
        }
        return this.__id;
    }

    get cm(){
        if (!this.__cm) {
            this.__cm = ClassnameManager.fromProps( this.props, this.classbase );
            
            this.__cm.on('change', ()=> {
                this.setStateProxy({
                    className: (this.__cm as ClassnameManager).getRootClass()
                });
            });
        }
        return this.__cm;
    }

    get rootClass(){
        return this.cm.getRootClass();
    }

    getClass(...subclasses:string[]) {
        return this.cm.getClass(...subclasses);
    }

    getRootClass() {
        return this.cm.getRootClass();
    }

    get classbase():string{
        throw new Error("classbase must be defined");
    }

    async delay(ms:number):Promise<void> {
        return new Promise(function(resolve, reject){
            setTimeout(function(){
                resolve(void 0);
            }, ms)
        });
    }


}

export default AbstractComponent;
import KewpieEventListener from "./KewpieEventListener.js";
import KewpieEvent from "./KewpieEvent.js";
import KewpiePath from "../KewpieCore/KewpiePath.js";

class KewpieEventDispatcher {
    #listeners = [];
    #observeCache = {};
    #parent;

    constructor(parent) {
        this.#parent = parent;
    }



    dispatch(event) {
        let listeners = this.getListenersForEvent(event);
        for (var ii=0; ii < listeners.length; ii++) {
            if (event.isPropagationStopped) break;

            let listener = listeners[ii];
            listener.handle(event);
        }
    }

    getAllObservers(){
        return this.#observeCache;
    }
    updateObserversHash(path, hash) {
        if (this.#observeCache[path]) {
            this.#observeCache[path].hash = hash;
        }
    }
    updateObserversValue(path, value) {
        if (this.#observeCache[path]) {
            this.#observeCache[path].oldValue = this.#observeCache[path].value;
            this.#observeCache[path].value = value;
        }
    }

    listen(type, callback, identifier = null, order=10000) {
        let listener = new KewpieEventListener({
            dataType: type,
            callback, 
            identifier,
            order
        });
        this.#listeners.push(listener);
        this.__cleanListeners();
        return listener;
    }

    observe(keypath, callback, identifier = null, order=100) {
        let kp = KewpiePath.coalesce(keypath);
        if (!this.#observeCache[kp.path]) {
            this.#observeCache[kp.path] = {
                value: this.#parent.first(kp),
                hash: this.#parent.hash(kp),
                keypath: kp
            }
        }
        let listener = new KewpieEventListener({
            dataType: KewpieEvent.TYPES.OBSERVER,
            callback, 
            identifier,
            keypath: kp,
            order
        });
        this.#listeners.push(listener);
        this.__cleanListeners();
        return listener;
    }

    unlisten(identifier) {
        let listeners = this.getListenersByIdentifier(identifier);
        for (var ii=0; ii < listeners.length; ii++) {
            listeners[ii].cancel();
        }
        this.__cleanListeners();
    }
    unobserve(identifier) {
        this.unlisten(identifier);
    }
    __cleanListeners(){
        let listeners = this.#listeners.filter((listener) => {
            return listener.ready;
        });

        listeners.sort((a,b) => {
            return a.order - b.order;
        });

        this.#listeners = listeners;
    }

    getListenersForEvent(event) {
        let ret = [];
        for (var ii=0; ii < this.#listeners.length; ii++) {
            let listener = this.#listeners[ii];

            if (listener.isListenerForEvent(event)) {
                ret.push(listener);
            }
        }
        return ret;
    }
    getListenersByIdentifier(identifier) {
        let ret = [];
        for (var ii=0; ii < this.#listeners.length; ii++) {
            let listener = this.#listeners[ii];
            if (listener.isListenerForIdentifier(identifier)) {
                ret.push(listener);
            }
        }
        return ret;
    }

}


export default KewpieEventDispatcher;
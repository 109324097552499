import React from 'react';
import AbstractComponent, { IStandardProps } from "../Shared/AbstractComponent";
import { ChevronRightFilled } from '@fluentui/react-icons';
import ComposedContent from "../Shared/ComposedContent";

export type IEasHero = IStandardProps;

class Hero extends AbstractComponent<IEasHero> {

    constructor(props: IEasHero) {
        super(props);
    }

    get classbase() {
        return 'hero';
    }

    render() {
        return (
            <header className={this.state.className}>
                <div className={this.getClass("inner")}>
                    <div className={this.getClass("content")}>
                        <div className={this.getClass("column", "one")}>
                            <ComposedContent
                                className={this.getClass("title-content")}
                                media={{
                                    type: 'image',
                                    src: '/assets/ms-logo.svg',
                                    alt: 'logo'
                                }}
                                text={{
                                    eyebrow: 'FinOps X Europe',
                                    header : {
                                        "tag" : "h1",
                                        "text" : "Microsoft event hub"
                                    },
                                    body: 'Explore this page to find resources and register for various Microsoft events and opportunities happening during the FinOps X Europe event in Barcelona.',
                                }}
                            />
                        </div>
                        <div className={this.getClass("column", "two")}>
                            <ComposedContent
                                className={this.getClass("chiclet")}
                                identifier="enable"
                                text={{
                                    header: 'Enable efficiency',
                                    body: 'Elevate your FinOps practice to accelerate AI adoption, enhance efficiency, and drive sustainable growth.',
                                    actions: [
                                        {
                                            as: 'link',
                                            href: 'https://azure.microsoft.com/en-us/solutions/finops/',
                                            text: 'Explore solutions',
                                            icon: <ChevronRightFilled />,
                                            iconPosition: 'after',
                                            target: "_blank"
                                        }
                                    ]
                                }}
                            />
                            <ComposedContent
                                className={this.getClass("chiclet")}
                                identifier="empower"
                                text={{
                                    header: 'Empower your team',
                                    body: 'Discover Microsoft learning resources to skill and empower your organization in FinOps, AI, and many other topics',
                                    actions: [
                                        {
                                            as: 'link',
                                            href: 'https://aka.ms/finopsX/learn',
                                            text: 'Learn more ',
                                            icon: <ChevronRightFilled />,
                                            iconPosition: 'after',
                                            target: "_blank"

                                        }
                                    ]
                                }}
                            />
                        </div>
                    </div>
                </div>

            </header>
        )
    }
}

export default Hero;
